import Divider from 'components/common/Divider';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { Button, Col, Form, Row } from 'react-bootstrap';
import { Link, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { UserGoogleLogin, UserLogin } from 'apis/endpoints';
import { setEmail, setIsLogged, setName, setUid, setUname, setAgencyID, setAgencyName } from 'store/userSlice';
import { useDispatch } from 'react-redux';
import { GoogleLogin, useGoogleLogin, useGoogleOneTapLogin } from '@react-oauth/google';
import Flex from 'components/common/Flex';
import { jwtDecode } from 'jwt-decode';
import ReactFacebookLogin from 'react-facebook-login';
import { FiFacebook } from 'react-icons/fi';


import icfacebook from 'assets/img/logos/facebook.png';
import { GoogleReCaptcha, useGoogleReCaptcha } from 'react-google-recaptcha-v3';
import { facebook_login_app_id } from 'apis/constants';

const LoginForm = ({ hasLabel, layout }) => {

  const navigate = useNavigate()
  const dispatch = useDispatch();


  const { executeRecaptcha } = useGoogleReCaptcha();

  const [isLoading, setIsLoading] = useState(false);


  // State
  const [formData, setFormData] = useState({
    email: '',
    password: '',
    remember: false
  });



  const successResponse = succRes => {
    if (succRes.status === 200) {
      dispatch(setUname(succRes?.data?.data?.user?.email))
      dispatch(setUid(succRes?.data?.data?.user?.id))
      dispatch(setEmail(succRes?.data?.data?.user?.email))
      dispatch(setName(succRes?.data?.data?.user?.name))
      dispatch(setIsLogged(true));
      dispatch(setAgencyID(succRes?.data?.data?.agency?.id));
      dispatch(setAgencyName(`${succRes?.data?.data?.agency?.name}`));

      setIsLoading(false);

      
      localStorage.setItem('MEIUserAuthToken', succRes?.data?.token);


      toast.success(`Logged in as ${formData.email}`, {
        theme: 'colored'
      });

      navigate('/');
    }
  };


  // Handler
  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!executeRecaptcha) {
      console.log('Execute recaptcha not yet available');
      return;
    }

    setIsLoading(true);


    const token = await executeRecaptcha('login');


    UserLogin(formData.email, formData.password, token)
      .then(res => successResponse(res))
      .catch(err => {

        setIsLoading(false);
        console.log(err, 'LoginError')
        toast.error(err?.response?.data?.error_messages[0] || 'Login Failed', {
          theme: 'colored'
        });
      });


  };

  const handleFieldChange = e => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value
    });
  };



  const handleGoogleLoginSuccess = (response) => {

    const usIn = jwtDecode(response.credential)
    UserGoogleLogin(usIn.email, response.clientId, "Google")
      .then(res => successResponse(res))
      .catch(err => {

        setIsLoading(false);
        console.log(err, 'LoginError')
        toast.error(err?.response?.data?.error_messages[0] || 'Login Failed', {
          theme: 'colored'
        });
      });

  };

  const handleFBLoginSuccess = (response, appID) => {
    UserGoogleLogin(response.email, appID, "Facebook")
      .then(res => successResponse(res))
      .catch(err => {

        setIsLoading(false);
        console.log(err, 'LoginError')
        toast.error(err?.response?.data?.error_messages[0] || 'Login Failed', {
          theme: 'colored'
        });
      });

  };

  const handleGoogleLoginFail = () => {
    toast.error('Sign in with google failed', {
      theme: 'colored'
    });
  };



  return (
    <Form onSubmit={handleSubmit}>
      <Form.Group className="mb-3">
        {hasLabel && <Form.Label>Email address</Form.Label>}
        <Form.Control
          placeholder={!hasLabel ? 'Email address' : ''}
          value={formData.email}
          name="email"
          onChange={handleFieldChange}
          type="email"
        />
      </Form.Group>

      <Form.Group className="mb-3">
        {hasLabel && <Form.Label>Password</Form.Label>}
        <Form.Control
          placeholder={!hasLabel ? 'Password' : ''}
          value={formData.password}
          name="password"
          onChange={handleFieldChange}
          type="password"
        />
      </Form.Group>

      <Row className="justify-content-between align-items-center">
        <Col xs="auto">
          <Form.Check type="checkbox" id="rememberMe" className="mb-0">
            <Form.Check.Input
              type="checkbox"
              name="remember"
              checked={formData.remember}
              onChange={e =>
                setFormData({
                  ...formData,
                  remember: e.target.checked
                })
              }
            />
            <Form.Check.Label className="mb-0 text-700">
              Remember me
            </Form.Check.Label>
          </Form.Check>
        </Col>

        <Col xs="auto">
          <Link
            className="fs--1 mb-0"
            to={`/forgot-password`}
          >
            Forgot Password?
          </Link>
        </Col>
      </Row>

      <Form.Group>
        <Button
          type="submit"
          color="primary"
          className="mt-3 w-100"
          disabled={!formData.email || !formData.password || isLoading}
        >
          Log in {isLoading && '...'}
        </Button>
      </Form.Group>

      <Divider className="mt-4">or</Divider>
      {/* <SocialAuthButtons /> */}


      <Flex alignItems={'center'} justifyContent={'center'} className={'mt-4 gx-3'} >
        <GoogleLogin
          onSuccess={credentialResponse => {
            handleGoogleLoginSuccess(credentialResponse);
          }}
          onError={() => {
            handleGoogleLoginFail();
          }}
          text='signin'
        />


        <ReactFacebookLogin
          appId={facebook_login_app_id}
          autoLoad={true}
          fields="name,email"
          // onClick={componentClicked}
          callback={(responseFacebook) => {
            if (responseFacebook.email)
              handleFBLoginSuccess(responseFacebook, {facebook_login_app_id})
          }}
          size='small'
          icon={<img src={icfacebook} width={20} className='me-2' />}
          tag={'button'}
          style={{ height: '40px' }}
          textButton='Sign in'
          cssClass='d-flex align-items-center py-2 px-2 btn border text-black fw-normal ms-4'
        // scope='email'


        />



      </Flex>

      
      <GoogleReCaptcha action="login" />


    </Form>
  );
};

LoginForm.propTypes = {
  layout: PropTypes.string,
  hasLabel: PropTypes.bool
};

LoginForm.defaultProps = {
  layout: 'simple',
  hasLabel: false
};

export default LoginForm;
