import React, { useMemo, useRef, useState } from "react";
import JoditEditor from "jodit-react";

import { base_url } from 'apis/constants';


const MEICKEditor = ({ editor }) => {

    const [fileType, setFileType] = useState(null);

    const getFileHtml = (burl, fileUrl) => {
        return ('<a href="' + burl + fileUrl + '" target="_blank">' + burl + fileUrl + '</a>')
    }


    const config = useMemo(
        () => (
            {
                readonly: false,
                placeholder: "Start typing...",

                uploader: {
                    url: `${base_url}/ckeditor/upload/`,
                    insertImageAsBase64URI: false,
                    prepareData: function (data) {
                        const file = data.get("files[0]");
                        data.append("upload", file);
                        return data;
                    },
                    isSuccess: (response) => !!response.url,
                    process: (response) => {
                        console.log("Upload Response:", response);
                        if (response.url) {
                            return {
                                files: [response.url,] || [],
                                path: response.url,
                                baseurl: base_url,
                            };
                        }
                        return { files: [] };
                    },
                    error: (error) => console.error("Upload Error:", error),
                    // defaultHandlerSuccess: function (data, resp) {
                    //     var i,
                    //         field = 'files';
                    //     if (data[field] && data[field].length) {
                    //         console.log(fileType)
                    //         for (i = 0; i < data[field].length; i += 1) {
                    //             this.s.insertImage(data.baseurl + data[field][i]);
                    //         }
                    //     }
                    // },
                    defaultHandlerSuccess: function (data, resp) {
                        var i, field = 'files';
                        if (data[field] && data[field].length) {
                            for (i = 0; i < data[field].length; i += 1) {
                                const fileUrl = data[field][i];
                                const fileExtension = fileUrl.split('.').pop().toLowerCase();

                                // Define valid image extensions
                                const imageExtensions = ["jpg", "jpeg", "png", "gif", "bmp", "webp", "svg"];

                                if (imageExtensions.includes(fileExtension)) {
                                    this.s.insertImage(data.baseurl + fileUrl);
                                } else {
                                    this.s.insertHTML(getFileHtml(data.baseurl, fileUrl));
                                }
                            }
                        }
                        setFileType(null);
                    }
                },
                // removeButtons: ["file"],
                showXPathInStatusbar: false,
            }
        ),
        []
    );


    return (
        <JoditEditor
            ref={editor}
            config={config}
        />
    )
};

export default MEICKEditor;
