import React, { useEffect, useState } from 'react';
import { Button, Card, Dropdown, Modal, OverlayTrigger, Tooltip } from 'react-bootstrap';
import CardDropdown from 'components/common/CardDropdown';
import { Link, useNavigate } from 'react-router-dom';
import Flex from 'components/common/Flex';
import Avatar from 'components/common/Avatar';
import AdvanceTableWrapper from 'components/common/advance-table/AdvanceTableWrapper';
import CustomersTableHeader from './CustomersTableHeader';
import AdvanceTablePagination from 'components/common/advance-table/AdvanceTablePagination';
import AdvanceTable from 'components/common/advance-table/AdvanceTable';
import { DeleteService, GetServices } from 'apis/endpoints';
import { useSelector } from 'react-redux';
import SoftBadge from 'components/common/SoftBadge';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import { FiAlertTriangle, FiDelete, FiEdit, FiEdit2, FiEdit3, FiTrash, FiTrash2 } from 'react-icons/fi';
import { toast } from 'react-toastify';








const Services = () => {
    const [services, setServices] = useState([]);

    const agencyID = useSelector(state => state.MEIUser.agencyID)


    const [deleteConfirmModal, setDeleteConfirmModal] = useState(false);
    const [deleteingName, setDeletingName] = useState({});


    function handleConfirmDeleteService() {

        DeleteService(deleteingName.id).then(res => {
            setDeleteConfirmModal(false)
            getServicesData()
            toast.success(`Service deleted successfully!`, {
                theme: 'colored'
            });
        }).catch(err => {
            const errMsg = Object.values(err?.response?.data)[0][0].includes('unique') ? 'Category with same name already exists!' : Object.values(err?.response?.data)[0][0];
            setDeleteConfirmModal(false)
            toast.error(errMsg || 'Service delete failed!', {
                theme: 'colored'
            });
        })
    }

    const getServicesData = () => {

        GetServices(agencyID).then(res => {

            setServices(res.data);

        }).catch(err => {

        })

    }


    useEffect(() => {

        getServicesData();


    }, [agencyID]);




    const columns = [
        {
            accessor: 'action-ed',
            Header: '',
            disableSortBy: true,
            cellProps: {
                className: 'text-start',
                style: { width: 120 }
            },

            Cell: (rowData) => {
                const navigate = useNavigate();


                function handleEditService() {
                    navigate('/services/update', { state: { service_id: rowData.row.original.id } })
                }

                function handleDeleteService() {
                    setDeletingName({ name: rowData.row.original.title, id: rowData.row.original.id })
                    setDeleteConfirmModal(true)
                }
                return (
                    <>
                        <OverlayTrigger placement='right' overlay={
                            <Tooltip>
                                View/Edit
                            </Tooltip>
                        }>
                            <Button variant='falcon-primary' className='me-1' size='sm' onClick={e => handleEditService()}>
                                <FiEdit />
                            </Button>
                        </OverlayTrigger>
                        <OverlayTrigger placement='right' overlay={
                            <Tooltip>
                                Delete
                            </Tooltip>
                        }>
                            <Button variant='falcon-danger' className='' size='sm' onClick={e => handleDeleteService()}>
                                <FiTrash2 />
                            </Button>
                        </OverlayTrigger>
                    </>
                );
            }
        },
        {
            accessor: 'title',
            Header: 'Title',
            headerProps: { className: 'pe-1' },
            cellProps: {
                className: 'py-2'
            },
            Cell: rowData => {
                const { title, } = rowData.row.original;
                return (
                    // <Link to="/e-commerce/customer-details">
                    <Flex alignItems="center">
                        <div className="flex-1">
                            <h5 className="mb-0 fs--1">{title}</h5>
                        </div>
                    </Flex>
                    // </Link>
                );
            }
        },
        {
            accessor: 'subtitle',
            Header: 'Sub Title',
            headerProps: { className: 'pe-1' },
            cellProps: {
                className: 'py-2'
            },
            Cell: rowData => {
                const { subtitle, } = rowData.row.original;
                return (
                    <Link to="/e-commerce/customer-details">
                        <Flex alignItems="center">
                            <div className="flex-1">
                                <h5 className="mb-0 fs--1">{subtitle}</h5>
                            </div>
                        </Flex>
                    </Link>
                );
            }
        },
        {
            accessor: 'category',
            Header: 'Category',
            headerProps: { className: 'pe-1' },
            cellProps: {
                className: 'py-2'
            },
            Cell: rowData => {
                const { category_name, } = rowData.row.original;
                return (
                    <Flex alignItems="center">
                        <div className="flex-1">
                            <h5 className="mb-0 fs--1">{category_name}</h5>
                        </div>
                    </Flex>
                );
            }
        },


        {
            accessor: 'status',
            Header: 'Status',
            headerProps: {
                className: 'text-center'
            },
            cellProps: {
                className: 'fs-0',
                style: { width: 130 }
            },
            Cell: rowData => {
                const { status } = rowData.row.original;
                return (
                    <SoftBadge
                        pill
                        bg={classNames({
                            success: status === true,
                            danger: status === false,
                        })}
                        className="d-flex flex-center w-fit-content"
                        style={{ width: 'auto' }}
                    >
                        <FontAwesomeIcon
                            icon={classNames({
                                check: status,
                                times: !status,
                            })}
                            transform="shrink-2"
                            className="me-2"
                        />
                        <p className="mb-0">
                            {status ? 'Active' : 'In-Active'}
                        </p>
                    </SoftBadge>
                );
            }
        },

    ];



    return (
        <>

            <Modal show={deleteConfirmModal} backdrop="static" onHide={() => setDeleteConfirmModal(false)} keyboard={false} centered>

                <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-vcenter" className='text-danger '>
                        <FiAlertTriangle className='me-2' />
                        Are you sure?
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>

                    <div className=' text-danger'>
                        {deleteingName.name} will be deleted permanently!
                    </div>
                    <div className=' d-flex justify-content-center mt-3'>

                        <Button variant='falcon-primary' className='me-3' size='sm' onClick={e => setDeleteConfirmModal(false)}>
                            Cancel
                        </Button>
                        <Button variant='falcon-danger' className='' size='sm' onClick={e => handleConfirmDeleteService()}>
                            Delete
                        </Button>


                    </div>

                </Modal.Body>

            </Modal>

            <AdvanceTableWrapper
                columns={columns}
                data={services}
                // selection
                sortable
                pagination
                perPage={100}
            >
                <Card className="mb-3">
                    <Card.Header>
                        <CustomersTableHeader table service={true} />
                    </Card.Header>
                    <Card.Body className="p-0">
                        <AdvanceTable
                            table
                            headerClassName="bg-200 text-900 text-nowrap align-middle"
                            rowClassName="align-middle white-space-nowrap"
                            tableProps={{
                                size: 'sm',
                                striped: true,
                                className: 'fs--1 mb-0 overflow-hidden'
                            }}
                        />
                    </Card.Body>
                    <Card.Footer>
                        <AdvanceTablePagination table />
                    </Card.Footer>
                </Card>
            </AdvanceTableWrapper>
        </>
    );
};

export default Services;
