import { base_url } from "./constants";
import axios from 'axios';




const axiosInstance = axios.create({
    baseURL: base_url
});

axiosInstance.interceptors.request.use(
    (config) => {
        const token = localStorage.getItem('MEIUserAuthToken');
        if (token) {
            config.headers['Authorization'] = `Bearer ${token}`;
        }
        return config;
    },
    (error) => {
        return Promise.reject(error);
    }
);



axiosInstance.interceptors.response.use(
    (response) => {
        return response;
    },
    (error) => {
        if (error.response && error.response.status === 401) {
            localStorage.removeItem('MEIUserAuthToken');
            window.location.reload()
            return Promise.reject(error);
        }
        return Promise.reject(error);
    }
);



//  AUTHENTICATION

export const UserLogin = async(email, password, token) =>{
    return await axios.post(`${base_url}/portal/login/`, {
        email: email,
        password: password,
        recaptcha: token
    });
};

export const UserGoogleLogin = async(email, client, appName) =>{
    return await axios.post(`${base_url}/portal/google-signin/`, {
        email: email,
        client_id: client,
        app_name: appName,
    });
};

export const UserSendForgetPasswordEmail = async(email) =>{
    return await axios.post(`${base_url}/portal/send-reset-password/`, {
        email: email
    });
};

export const UserResetPassword = async(uid, token, p1, p2) =>{
    return await axios.post(`${base_url}/portal/reset-password/${uid}/${token}/`, {
        password: p1,
        password2: p2
    });
};



export const UserChangePassword = async(email, op, p1, p2) =>{
    return await axiosInstance.post(`${base_url}/portal/change-password/`, {
        email: email,
        password: op,
        new_password: p1,
        new_password2: p2
    });
};






export const AgencyGetProfile = async(agencyID) =>{
    return await axiosInstance.get(`${base_url}/portal/agency-profile/${agencyID}/`);
};

export const AgencyPostProfile = async(agencyID, data) =>{
    return await axiosInstance.post(`${base_url}/portal/agency-profile/${agencyID}/`, data);
};



export const AgencyGetMarketingCredentials = async(agencyID) =>{
    return await axiosInstance.get(`${base_url}/portal/agency-marketing-credentials/${agencyID}/`);
};

export const AgencyPostMarketingCredentials = async(agencyID, data) =>{
    return await axiosInstance.post(`${base_url}/portal/agency-marketing-credentials/${agencyID}/`, data);
};




//   TEAMMEMBERS

export const GetTeamMembers = async(agencyID) =>{
    return await axiosInstance.get(`${base_url}/portal/team-members/?agency_id=${agencyID}`);
};


export const GetTeamMember = async(id) =>{
    return await axiosInstance.get(`${base_url}/portal/team-members/${id}/`);
};



export const PostTeamMember = async(data) =>{
    return await axiosInstance.post(`${base_url}/portal/team-members/`, data);
};

export const UpdatePostTeamMember = async(id, data) =>{
    return await axiosInstance.post(`${base_url}/portal/team-members/${id}/`, data);
};



export const DeleteTeamMember = async(id) =>{
    return await axiosInstance.delete(`${base_url}/portal/team-members/${id}/`);
};




//   CLIENTS

export const GetClients = async(agencyID) =>{
    return await axiosInstance.get(`${base_url}/portal/clients/?agency_id=${agencyID}`);
};


export const GetClient = async(id) =>{
    return await axiosInstance.get(`${base_url}/portal/clients/${id}/`);
};



export const PostClient = async(data) =>{
    return await axiosInstance.post(`${base_url}/portal/clients/`, data);
};

export const UpdatePostClient = async(id, data) =>{
    return await axiosInstance.post(`${base_url}/portal/clients/${id}/`, data);
};



export const DeleteClient = async(id) =>{
    return await axiosInstance.delete(`${base_url}/portal/clients/${id}/`);
};




//   Offices

export const GetOffices = async(agencyID) =>{
    return await axiosInstance.get(`${base_url}/portal/offices/?agency_id=${agencyID}`);
};


export const GetOffice = async(id) =>{
    return await axiosInstance.get(`${base_url}/portal/offices/${id}/`);
};



export const PostOffice = async(data) =>{
    return await axiosInstance.post(`${base_url}/portal/offices/`, data);
};

export const UpdatePostOffice = async(id, data) =>{
    return await axiosInstance.post(`${base_url}/portal/offices/${id}/`, data);
};



export const DeleteOffice = async(id) =>{
    return await axiosInstance.delete(`${base_url}/portal/offices/${id}/`);
};



//   BANNERS

export const GetBanners = async(agencyID) =>{
    return await axiosInstance.get(`${base_url}/portal/banners/?agency_id=${agencyID}`);
};


export const GetBanner = async(id) =>{
    return await axiosInstance.get(`${base_url}/portal/banners/${id}/`);
};



export const PostBanner = async(data) =>{
    return await axiosInstance.post(`${base_url}/portal/banners/`, data);
};

export const UpdatePostBanner = async(id, data) =>{
    return await axiosInstance.post(`${base_url}/portal/banners/${id}/`, data);
};



export const DeleteBanner = async(id) =>{
    return await axiosInstance.delete(`${base_url}/portal/banners/${id}/`);
};


export const PostBannerMedia = async(data) =>{
    return await axiosInstance.post(`${base_url}/portal/add-banner-media/`, data);
};



//   PAGES

export const GetPages = async(agencyID) =>{
    return await axiosInstance.get(`${base_url}/portal/pages/?agency_id=${agencyID}`);
};


export const GetPage = async(id) =>{
    return await axiosInstance.get(`${base_url}/portal/pages/${id}/`);
};



export const UpdatePageBanner = async(id, data) =>{
    return await axiosInstance.post(`${base_url}/portal/pages/${id}/`, data);
};




//   Team Categories

export const GetTCategories = async(agencyID) =>{
    return await axiosInstance.get(`${base_url}/portal/team-categories/?agency_id=${agencyID}`);
};


export const GetTCategory = async(id) =>{
    return await axiosInstance.get(`${base_url}/portal/team-categories/${id}/`);
};



export const PostTCategory = async(data) =>{
    return await axiosInstance.post(`${base_url}/portal/team-categories/`, data);
};

export const UpdatePostTCategory = async(id, data) =>{
    return await axiosInstance.post(`${base_url}/portal/team-categories/${id}/`, data);
};



export const DeleteTCateg = async(id) =>{
    return await axiosInstance.delete(`${base_url}/portal/team-categories/${id}/`);
};


//   Service Categories

export const GetSCategories = async(agencyID) =>{
    return await axiosInstance.get(`${base_url}/portal/service-categories/?agency_id=${agencyID}`);
};


export const GetSCategory = async(id) =>{
    return await axiosInstance.get(`${base_url}/portal/service-categories/${id}/`);
};



export const PostSCategory = async(data) =>{
    return await axiosInstance.post(`${base_url}/portal/service-categories/`, data);
};

export const UpdatePostSCategory = async(id, data) =>{
    return await axiosInstance.post(`${base_url}/portal/service-categories/${id}/`, data);
};



export const DeleteSCateg = async(id) =>{
    return await axiosInstance.delete(`${base_url}/portal/service-categories/${id}/`);
};



//   Services

export const GetServices = async(agencyID) =>{
    return await axiosInstance.get(`${base_url}/portal/services/?agency_id=${agencyID}`);
};


export const GetService = async(id) =>{
    return await axiosInstance.get(`${base_url}/portal/services/${id}/`);
};



export const PostService = async(data) =>{
    return await axiosInstance.post(`${base_url}/portal/services/`, data);
};

export const UpdatePostService = async(id, data) =>{
    return await axiosInstance.post(`${base_url}/portal/services/${id}/`, data);
};



export const DeleteService = async(id) =>{
    return await axiosInstance.delete(`${base_url}/portal/services/${id}/`);
};



//   Product Categories

export const GetPrdCategories = async(agencyID) =>{
    return await axiosInstance.get(`${base_url}/portal/product-categories/?agency_id=${agencyID}`);
};


export const GetPrdCategory = async(id) =>{
    return await axiosInstance.get(`${base_url}/portal/product-categories/${id}/`);
};



export const PostPrdCategory = async(data) =>{
    return await axiosInstance.post(`${base_url}/portal/product-categories/`, data);
};

export const UpdatePostPrdCategory = async(id, data) =>{
    return await axiosInstance.post(`${base_url}/portal/product-categories/${id}/`, data);
};



export const DeletePrdCateg = async(id) =>{
    return await axiosInstance.delete(`${base_url}/portal/product-categories/${id}/`);
};



//   Products

export const GetProducts = async(agencyID) =>{
    return await axiosInstance.get(`${base_url}/portal/products/?agency_id=${agencyID}`);
};


export const GetProduct = async(id) =>{
    return await axiosInstance.get(`${base_url}/portal/products/${id}/`);
};



export const PostProduct = async(data) =>{
    return await axiosInstance.post(`${base_url}/portal/products/`, data);
};

export const UpdatePostProduct = async(id, data) =>{
    return await axiosInstance.post(`${base_url}/portal/products/${id}/`, data);
};



export const DeleteProduct = async(id) =>{
    return await axiosInstance.delete(`${base_url}/portal/products/${id}/`);
};

//   Counters

export const GetCounters = async(agencyID) =>{
    return await axiosInstance.get(`${base_url}/portal/counters/?agency_id=${agencyID}`);
};


export const GetCounter = async(id) =>{
    return await axiosInstance.get(`${base_url}/portal/counters/${id}/`);
};



export const PostCounter = async(data) =>{
    return await axiosInstance.post(`${base_url}/portal/counters/`, data);
};

export const UpdatePostCounter = async(id, data) =>{
    return await axiosInstance.post(`${base_url}/portal/counters/${id}/`, data);
};



export const DeleteCounter = async(id) =>{
    return await axiosInstance.delete(`${base_url}/portal/counters/${id}/`);
};




//   Download Categories

export const GetDnlCategories = async(agencyID) =>{
    return await axiosInstance.get(`${base_url}/portal/download-categories/?agency_id=${agencyID}`);
};


export const GetDnlCategory = async(id) =>{
    return await axiosInstance.get(`${base_url}/portal/download-categories/${id}/`);
};



export const PostDnlCategory = async(data) =>{
    return await axiosInstance.post(`${base_url}/portal/download-categories/`, data);
};

export const UpdatePostDnlCategory = async(id, data) =>{
    return await axiosInstance.post(`${base_url}/portal/download-categories/${id}/`, data);
};



export const DeleteDnlCateg = async(id) =>{
    return await axiosInstance.delete(`${base_url}/portal/download-categories/${id}/`);
};



//   Download

export const GetDownloads = async(agencyID) =>{
    return await axiosInstance.get(`${base_url}/portal/downloads/?agency_id=${agencyID}`);
};


export const GetDownload = async(id) =>{
    return await axiosInstance.get(`${base_url}/portal/downloads/${id}/`);
};



export const PostDownload = async(data) =>{
    return await axiosInstance.post(`${base_url}/portal/downloads/`, data);
};

export const UpdatePostDownload = async(id, data) =>{
    return await axiosInstance.post(`${base_url}/portal/downloads/${id}/`, data);
};



export const DeleteDownload = async(id) =>{
    return await axiosInstance.delete(`${base_url}/portal/downloads/${id}/`);
};



//   Media Categories

export const GetMedCategories = async(agencyID) =>{
    return await axiosInstance.get(`${base_url}/portal/media-categories/?agency_id=${agencyID}`);
};


export const GetMedCategory = async(id) =>{
    return await axiosInstance.get(`${base_url}/portal/media-categories/${id}/`);
};



export const PostMedCategory = async(data) =>{
    return await axiosInstance.post(`${base_url}/portal/media-categories/`, data);
};

export const UpdatePostMedCategory = async(id, data) =>{
    return await axiosInstance.post(`${base_url}/portal/media-categories/${id}/`, data);
};



export const DeleteMedCateg = async(id) =>{
    return await axiosInstance.delete(`${base_url}/portal/media-categories/${id}/`);
};



//   Media

export const GetMedias = async(agencyID) =>{
    return await axiosInstance.get(`${base_url}/portal/medias/?agency_id=${agencyID}`);
};


export const GetMedia = async(id) =>{
    return await axiosInstance.get(`${base_url}/portal/medias/${id}/`);
};



export const PostMedia = async(data) =>{
    return await axiosInstance.post(`${base_url}/portal/medias/`, data);
};

export const UpdatePostMedia = async(id, data) =>{
    return await axiosInstance.post(`${base_url}/portal/medias/${id}/`, data);
};



export const DeleteMedia = async(id) =>{
    return await axiosInstance.delete(`${base_url}/portal/medias/${id}/`);
};




//   Blog Categories

export const GetBlgCategories = async(agencyID) =>{
    return await axiosInstance.get(`${base_url}/portal/blog-categories/?agency_id=${agencyID}`);
};


export const GetBlgCategory = async(id) =>{
    return await axiosInstance.get(`${base_url}/portal/blog-categories/${id}/`);
};



export const PostBlgCategory = async(data) =>{
    return await axiosInstance.post(`${base_url}/portal/blog-categories/`, data);
};

export const UpdatePostBlgCategory = async(id, data) =>{
    return await axiosInstance.post(`${base_url}/portal/blog-categories/${id}/`, data);
};



export const DeleteBlgCateg = async(id) =>{
    return await axiosInstance.delete(`${base_url}/portal/blog-categories/${id}/`);
};



//   Blogs

export const GetBlogs = async(agencyID) =>{
    return await axiosInstance.get(`${base_url}/portal/blogs/?agency_id=${agencyID}`);
};


export const GetBlog = async(id) =>{
    return await axiosInstance.get(`${base_url}/portal/blogs/${id}/`);
};



export const PostBlog = async(data) =>{
    return await axiosInstance.post(`${base_url}/portal/blogs/`, data);
};

export const UpdatePostBlog = async(id, data) =>{
    return await axiosInstance.post(`${base_url}/portal/blogs/${id}/`, data);
};



export const DeleteBlog = async(id) =>{
    return await axiosInstance.delete(`${base_url}/portal/blogs/${id}/`);
};






//   Project Categories

export const GetPCategories = async(agencyID) =>{
    return await axiosInstance.get(`${base_url}/portal/project-categories/?agency_id=${agencyID}`);
};


export const GetPCategory = async(id) =>{
    return await axiosInstance.get(`${base_url}/portal/project-categories/${id}/`);
};



export const PostPCategory = async(data) =>{
    return await axiosInstance.post(`${base_url}/portal/project-categories/`, data);
};

export const UpdatePostPCategory = async(id, data) =>{
    return await axiosInstance.post(`${base_url}/portal/project-categories/${id}/`, data);
};



export const DeletePCateg = async(id) =>{
    return await axiosInstance.delete(`${base_url}/portal/project-categories/${id}/`);
};


//   Industry
export const GetIndustrys = async() =>{
    return await axiosInstance.get(`${base_url}/portal/industeries/`);
};


//   Projects
export const GetProjects = async(agencyID) =>{
    return await axiosInstance.get(`${base_url}/portal/projects/?agency_id=${agencyID}`);
};


export const GetProject = async(id) =>{
    return await axiosInstance.get(`${base_url}/portal/projects/${id}/`);
};



export const PostProject = async(data) =>{
    return await axiosInstance.post(`${base_url}/portal/projects/`, data);
};

export const UpdatePostProject = async(id, data) =>{
    return await axiosInstance.post(`${base_url}/portal/projects/${id}/`, data);
};



export const DeleteProject = async(id) =>{
    return await axiosInstance.delete(`${base_url}/portal/projects/${id}/`);
};




