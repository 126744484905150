import React, { useEffect, useRef, useState } from 'react';
import { Card, Col, Form, FormControl, InputGroup, Row, Button, Accordion, Modal, Spinner } from 'react-bootstrap';
import { FormProvider } from 'react-hook-form';


import cloudUpload from 'assets/img/icons/cloud-upload.svg';




import Flex from 'components/common/Flex';

import { useSelector } from 'react-redux';
import { GetPCategories, GetProject, PostBannerMedia, UpdatePostProject } from 'apis/endpoints';

import { FiDelete } from 'react-icons/fi';
import { toast } from 'react-toastify';
import { useLocation, useNavigate } from 'react-router-dom';
import { Link } from 'react-router-dom';
import { base_url } from 'apis/constants';
import MEICKEditor from 'components/mei/components/MEICKEditor';



const UpdateProject = () => {

    const navigate = useNavigate();

    const location = useLocation();
    const sID = location.state?.project_id || 0;
    const agencyID = useSelector(state => state.MEIUser.agencyID);


    const [loadingModal, setLoadingModal] = useState(false)

    const mediaObj = {

        pkid: 0,

        index: 1,

        mediaType: 'image',

        bgAltText: '',
        bgHeight: '',
        bgWidth: '',

        fgAltText: '',
        fgHeight: '',
        fgWidth: '',


        vAutoPlay: true,
        vControls: true,
        vLoop: true,
        vMuted: true,

        videoLink: '',
        vdHeight: '',
        vdWidth: '',


        bgImage: null,
        fgImage: null,
        bgVideo: null,
        vtImage: null,

        bgImageURL: null,
        fgImageURL: null,
        bgVideoURL: null,
        vtImageURL: null,

        bgImagePT: null,
        fgImagePT: null,
        bgVideoPT: null,
        vtImagePT: null,

    };

    const [bannerMedia, setBannerMedia] = useState([]);



    const editor = useRef(null);
    const [bannerData, setBannerData] = useState({
        title: '',
        subtitle: '',
        description: '',
        shortDesc: '',
        alignTitle: 'center',
        status: true,
        metaDesc: '',
        metaTags: '',
        showHeader: true,

        showIndex: false,
        category: ''
    });


    const [parentOptions, setParentOptions] = useState([]);


    useEffect(() => {

        GetPCategories(agencyID).then(res => {
            setParentOptions(res.data)
        })

    }, [])




    const loadServiceData = () => {

        GetProject(sID).then(res => {
            setBannerData({
                title: res.data.title,
                subtitle: res.data.subtitle,
                description: res.data.description || '',
                shortDesc: res.data.short_description,
                alignTitle: res.data.align_content,
                metaDesc: res.data.meta_description,
                metaTags: res.data.meta_keywords,
                showHeader: res.data.show_header,
                category: res.data.category,

                showIndex: res.data.show_index,
                status: res.data.status,

            });


            editor.current.value = res.data.description

            setBannerMedia([
            ]
            );




            setBannerMedia(res.data.media.map((bnm, indexx) => (
                {
                    pkid: bnm.id,

                    index: indexx + 1,

                    mediaType: bnm.media_type,

                    bgAltText: bnm.bg_alt_text || '',
                    bgHeight: bnm.bg_height || '',
                    bgWidth: bnm.bg_width || '',

                    fgAltText: bnm.fg_alt_text || '',
                    fgHeight: bnm.fg_height || '',
                    fgWidth: bnm.fg_width || '',


                    vAutoPlay: bnm.v_autoplay,
                    vControls: bnm.v_controls,
                    vLoop: bnm.v_loop,
                    vMuted: bnm.v_muted,

                    videoLink: bnm.video_link || '',
                    vdHeight: bnm.vd_height || '',
                    vdWidth: bnm.vd_width || '',


                    bgImage: null,
                    fgImage: null,
                    bgVideo: null,
                    vtImage: null,

                    bgImageURL: bnm.bg_image ? `${base_url}${bnm.bg_image}` : bnm.bg_video ? `${base_url}${bnm.bg_video}` : null,
                    fgImageURL: bnm.fg_image ? `${base_url}${bnm.fg_image}` : null,
                    bgVideoURL: bnm.bg_video ? `${base_url}${bnm.bg_video}` : null,
                    vtImageURL: bnm.vt_image ? `${base_url}${bnm.vt_image}` : null,

                    bgImagePT: null,
                    fgImagePT: null,
                    bgVideoPT: null,
                    vtImagePT: null,

                }
            )))







        }).catch(err => {
            console.log(err)
        });

    };

    useEffect(() => {
        loadServiceData();
    }, [sID]);


    function postBannerMedia(value, sid) {
        const finalMediaData = new FormData();

        finalMediaData.append('pk', value.pkid)

        finalMediaData.append('media_type', value.mediaType)

        finalMediaData.append('bg_alt_text', value.bgAltText)
        finalMediaData.append('bg_height', parseInt(value.bgHeight, 10) || 0)
        finalMediaData.append('bg_width', parseInt(value.bgWidth, 10) || 0)

        finalMediaData.append('fg_alt_text', value.fgAltText)
        finalMediaData.append('fg_height', parseInt(value.fgHeight, 10) || 0)
        finalMediaData.append('fg_width', parseInt(value.fgWidth, 10) || 0)


        finalMediaData.append('video_link', value.videoLink || '')

        finalMediaData.append('v_autoplay', value.vAutoPlay)
        finalMediaData.append('v_controls', value.vControls)
        finalMediaData.append('v_loop', value.vLoop)
        finalMediaData.append('v_muted', value.vMuted)

        if (!value.bgImage && !value.bgImageURL)
            finalMediaData.append('bg_image', '')

        if (!value.fgImage && !value.fgImageURL)
            finalMediaData.append('fg_image', '')


        if (!value.vtImage && !value.vtImageURL)
            finalMediaData.append('vt_image', '')

        if (!value.bgVideo && !value.bgImageURL)
            finalMediaData.append('bg_video', '')


        if (value.bgImage)
            finalMediaData.append('bg_image', value.bgImage)

        if (value.fgImage)
            finalMediaData.append('fg_image', value.fgImage)


        if (value.vtImage)
            finalMediaData.append('vt_image', value.vtImage)

        if (value.bgVideo)
            finalMediaData.append('bg_video', value.bgVideo)


        finalMediaData.append('banner_id', 0)

        finalMediaData.append('project_id', sid)

        PostBannerMedia(finalMediaData);

    }


    const handleSubmitBanner = (e) => {
        e.preventDefault();

        setLoadingModal(true)


        const medobjArr = bannerMedia.filter(bn => bn.pkid !== 0).map((bn) => bn.pkid)

        const finalData = {
            media: [],
            media_ids: medobjArr,
            title: bannerData.title,
            align_content: bannerData.alignTitle,
            show_header: bannerData.showHeader,
            subtitle: bannerData.subtitle,
            short_description: bannerData.shortDesc,
            description: editor.current.value,

            meta_description: bannerData.metaDesc,
            meta_keywords: bannerData.metaTags,

            status: bannerData.status,
            category: bannerData.category,

            
            show_index: bannerData.showIndex,

            agency: agencyID
        }


        UpdatePostProject(sID, finalData).then(res => {
            // bannerMedia.forEach((bmedia) => postBannerMedia(bmedia, res.data.id));
            const promises = bannerMedia.map(bmedia => postBannerMedia(bmedia, res.data.id));
            Promise.all(promises).then(() => {
                setLoadingModal(false);
                toast.success(`Project updated successfully!`, {
                    theme: 'colored'
                });
                navigate('/projects')
            })
        }).catch(err => {
            const errMsg = Object.values(err?.response?.data)[0][0].includes('unique') ? 'Category with same name already exists!' : Object.values(err?.response?.data)[0][0];
            setLoadingModal(false);
            toast.error(errMsg || 'Update project failed!', {
                theme: 'colored'
            });
        });



    };

    const handleAddMedia = () => {

        setBannerMedia([
            ...bannerMedia,
            {
                ...mediaObj,
                index: bannerMedia[bannerMedia.length - 1].index + 1
            }
        ])

    }

    const handleVideoUpload = (event) => {
        const file = event.target.files[0];
        const videoType = /^video\/.*/; // Regex to match any video type

        if (!file?.type?.match(videoType)) {
            toast.error('Only video files are allowed', {
                theme: 'colored'
            });
            return null;
        }

        return { file: file, url: URL.createObjectURL(file), path: file.name }
    };



    const handleImageUpload = (event) => {
        const file = event.target.files[0];
        const imageType = /^(?!.*\.webp$)image.*/;

        if (!file?.type?.match(imageType)) {
            toast.error('Only image files are allowed', {
                theme: 'colored'
            });
            return null;
        }
        if (file?.type?.match(imageType)) {
            return { file: file, url: URL.createObjectURL(file), path: file.name }
        }
    };


    return (
        <FormProvider>

            <Modal show={loadingModal} backdrop="static" keyboard={false} centered>

                <Modal.Body>

                    <div className='d-flex justify-content-center'>
                        <Spinner animation="border" variant="info" size='lg' />
                    </div>

                </Modal.Body>

            </Modal>

            <Form onSubmit={handleSubmitBanner}>
                <Row className="g-3">
                    <Col xs={12}>
                        <Card>
                            <Card.Body>
                                <Row className="flex-between-center">
                                    <Col md>
                                        <h5 className="mb-2 mb-md-0">Edit Project</h5>
                                    </Col>
                                    <Col xs="auto">
                                        <Link to={'/projects'}>
                                            <Button
                                                variant="link"
                                                className="text-secondary fw-medium p-0 me-3"
                                                type="button"
                                            >
                                                Discard
                                            </Button>
                                        </Link>
                                        <Button variant="primary" type="submit">
                                            Update project
                                        </Button>
                                    </Col>
                                </Row>
                            </Card.Body>
                        </Card>
                    </Col>
                    <Col lg={8}>

                        <Card className="mb-3">
                            <Card.Header as="h6" className="bg-light">
                                Basic information
                            </Card.Header>
                            <Card.Body>
                                <Row className="gx-2 gy-3">
                                    <Col md="12">
                                        <Form.Group>
                                            <Form.Label>Project Title:</Form.Label>
                                            <InputGroup className="mb-1">
                                                <FormControl value={bannerData.title} onChange={e => setBannerData({ ...bannerData, title: e.target.value })} required />
                                            </InputGroup>

                                            <Form.Control.Feedback type="invalid">
                                                {/* {errors.productName?.message} */}
                                            </Form.Control.Feedback>
                                        </Form.Group>
                                    </Col>
                                    <Col md="12">
                                        <Form.Group>
                                            <Form.Label>Subtitle:</Form.Label>
                                            <InputGroup className="mb-1">
                                                <FormControl value={bannerData.subtitle} onChange={e => setBannerData({ ...bannerData, subtitle: e.target.value })} />
                                            </InputGroup>

                                            <Form.Control.Feedback type="invalid">
                                                {/* {errors.productName?.message} */}
                                            </Form.Control.Feedback>
                                        </Form.Group>
                                    </Col>

                                    <Col md="12">
                                        <Form.Group>
                                            <Form.Label>Project Category:</Form.Label>
                                            <Form.Select value={bannerData.category} onChange={e => setBannerData({ ...bannerData, category: e.target.value })}>
                                                <option value="">----</option>
                                                {parentOptions.map((pr) => (
                                                    <option value={pr.id}>{pr.name}</option>
                                                ))
                                                }

                                            </Form.Select>
                                        </Form.Group>
                                    </Col>


                                </Row>
                            </Card.Body>
                        </Card>


                        <Card className="mb-3">
                            <Card.Header as="h6" className="bg-light">

                                Description

                            </Card.Header>
                            <Card.Body>
                                <Row className="gx-2 gy-3">
                                    <Col xs="12">


                                        <Form.Group className='mb-5'>
                                            <Form.Label>Short Description:</Form.Label>
                                            <FormControl as="textarea" rows={4} value={bannerData.shortDesc} onChange={e => setBannerData({ ...bannerData, shortDesc: e.target.value })} />
                                        </Form.Group>



                                        <Form.Label>Description:</Form.Label>
                                        
                                        <MEICKEditor editor={editor} />
                                    </Col>

                                </Row>
                            </Card.Body>
                        </Card>



                        <Card className="mb-3">
                            <Card.Header as="h6" className="bg-light">
                                Project Media
                            </Card.Header>
                            <Card.Body>

                                <Accordion defaultActiveKey={0}>
                                    {bannerMedia.map((bann, index) => (
                                        <Accordion.Item key={index} eventKey={index}>

                                            <Accordion.Header>
                                                <div className='text-primary small mb-2 mt-2 d-flex justify-content-between w-100'>
                                                    Project Media {index + 1}


                                                    {bann.index > 1 &&
                                                        <Button
                                                            variant="link"
                                                            className="text-danger p-0 me-3 small"
                                                            type="button"
                                                            onClick={e => {

                                                                setBannerMedia(bannerMedia.filter(baan => baan.index !== bann.index))

                                                            }}
                                                        >
                                                            <span className='small'>Remove</span>
                                                        </Button>
                                                    }

                                                </div>
                                            </Accordion.Header>

                                            <Accordion.Body>


                                                <div>

                                                    <Col md="6">
                                                        <Form.Group>
                                                            <Form.Label>Background Type:</Form.Label>
                                                            <Form.Select value={bann.mediaType} onChange={e => {
                                                                const updatedBannerMedia = bannerMedia.map((item, i) =>
                                                                    bann.index === item.index ? { ...item, mediaType: e.target.value } : item
                                                                );
                                                                setBannerMedia(updatedBannerMedia)
                                                            }}>
                                                                <option value="image">Image</option>
                                                                <option value="video">Video</option>
                                                            </Form.Select>
                                                        </Form.Group>
                                                    </Col>




                                                    {bann.mediaType === 'image' ?


                                                        <>

                                                            <Form.Label className='mt-3'>Background Image:</Form.Label>
                                                            <Row className='mb-3'>
                                                                <Col md={6}>
                                                                    <Form.Label>Alt Text:</Form.Label>
                                                                    <FormControl value={bann.bgAltText} onChange={e => {
                                                                        const updatedBannerMedia = bannerMedia.map((item, i) =>
                                                                            bann.index === item.index ? { ...item, bgAltText: e.target.value } : item
                                                                        );
                                                                        setBannerMedia(updatedBannerMedia)
                                                                    }} />
                                                                </Col>
                                                                <Col md={3}>
                                                                    <Form.Label>Height:</Form.Label>
                                                                    <FormControl type="number" value={bann.bgHeight} onChange={e => {
                                                                        const updatedBannerMedia = bannerMedia.map((item, i) =>
                                                                            bann.index === item.index ? { ...item, bgHeight: e.target.value } : item
                                                                        );
                                                                        setBannerMedia(updatedBannerMedia)
                                                                    }} />
                                                                </Col>
                                                                <Col md={3}>
                                                                    <Form.Label>Width:</Form.Label>
                                                                    <FormControl type="number" value={bann.bgWidth} onChange={e => {
                                                                        const updatedBannerMedia = bannerMedia.map((item, i) =>
                                                                            bann.index === item.index ? { ...item, bgWidth: e.target.value } : item
                                                                        );
                                                                        setBannerMedia(updatedBannerMedia)
                                                                    }} />
                                                                </Col>
                                                            </Row>
                                                            <input id={`bgimg-${bann.index}`} className='d-none' type='file' onChange={e => {
                                                                const file = handleImageUpload(e);
                                                                if (file) {
                                                                    const updatedBannerMedia = bannerMedia.map((item, i) =>
                                                                        bann.index === item.index ? { ...item, bgImage: file.file, bgImageURL: file.url, bgImagePT: file.path } : item
                                                                    );
                                                                    setBannerMedia(updatedBannerMedia)
                                                                }

                                                            }} />
                                                            <label htmlFor={`bgimg-${bann.index}`} className='dropzone-area py-1 d-flex flex-column justify-content-center position-relative' style={{ height: 180 }} >
                                                                <div className='position-absolute' style={{ right: 10, top: 20 }}>
                                                                    <Button variant="link" className="text-secondary fw-medium p-0 me-3" type="button" onClick={e => {
                                                                        const updatedBannerMedia = bannerMedia.map((item, i) =>
                                                                            bann.index === item.index ? { ...item, bgImage: null, bgImageURL: null, bgImagePT: null } : item
                                                                        );
                                                                        setBannerMedia(updatedBannerMedia)
                                                                    }}>
                                                                        <FiDelete className='text-danger' />
                                                                    </Button>
                                                                </div>
                                                                <div className="mb-2">
                                                                    <img
                                                                        className="rounded me-2"
                                                                        width={80}
                                                                        style={{ maxHeight: 80 }}
                                                                        src={bann.bgImageURL}
                                                                    />
                                                                    {bann.bgImagePT}
                                                                </div>
                                                                <Flex justifyContent='center mt-4'>
                                                                    <p className="fs-0 mb-0 text-700 me-1">Select your file here</p>
                                                                    <img src={cloudUpload} alt="" width={25} className="me-2" />
                                                                </Flex>
                                                            </label>
                                                        </>

                                                        :

                                                        <>

                                                            <Form.Label className='mt-3 mb-0 '>Background Video:</Form.Label>
                                                            <div className='text-500 mb-2 small'>Paste a video link or upload video</div>
                                                            <Row className='mb-3'>
                                                                <Col md={12}>
                                                                    <Form.Label>Video Link:</Form.Label>
                                                                    <FormControl value={bann.videoLink} onChange={e => {
                                                                        const updatedBannerMedia = bannerMedia.map((item, i) =>
                                                                            bann.index === item.index ? { ...item, videoLink: e.target.value } : item
                                                                        );
                                                                        setBannerMedia(updatedBannerMedia)
                                                                    }} type='url' />
                                                                </Col>
                                                            </Row>
                                                            <Form.Label className='mt-1 mb-0 '>Upload Video:</Form.Label>
                                                            <input id={`bgvid-${bann.index}`} className='d-none' type='file' onChange={e => {
                                                                const file = handleVideoUpload(e);
                                                                if (file) {
                                                                    const updatedBannerMedia = bannerMedia.map((item, i) =>
                                                                        bann.index === item.index ? { ...item, bgVideo: file.file, bgImageURL: file.url, bgImagePT: file.path } : item
                                                                    );
                                                                    setBannerMedia(updatedBannerMedia)
                                                                }

                                                            }} />
                                                            <label htmlFor={`bgvid-${bann.index}`} className='dropzone-area py-1 d-flex flex-column justify-content-center position-relative' style={{ height: 180 }} >
                                                                <div className='position-absolute' style={{ right: 10, top: 20 }}>
                                                                    <Button variant="link" className="text-secondary fw-medium p-0 me-3" type="button" onClick={e => {
                                                                        const updatedBannerMedia = bannerMedia.map((item, i) =>
                                                                            bann.index === item.index ? { ...item, bgVideo: null, bgImageURL: null, bgImagePT: null } : item
                                                                        );
                                                                        setBannerMedia(updatedBannerMedia)
                                                                    }}>
                                                                        <FiDelete className='text-danger' />
                                                                    </Button>
                                                                </div>
                                                                <div className="mb-2">
                                                                    {bann.bgImageURL &&
                                                                        <video
                                                                            className="rounded me-2"
                                                                            width={80}
                                                                            style={{ maxHeight: 80 }}
                                                                            src={bann.bgImageURL}
                                                                        />
                                                                    }
                                                                    {bann.bgImagePT}
                                                                </div>
                                                                <Flex justifyContent='center mt-4'>
                                                                    <p className="fs-0 mb-0 text-700 me-1">Select your file here</p>
                                                                    <img src={cloudUpload} alt="" width={25} className="me-2" />
                                                                </Flex>
                                                            </label>

                                                            <Form.Label className='mt-1 mb-0 '>Thumbnail Image:</Form.Label>
                                                            <input id={`vtimg-${bann.index}`} className='d-none' type='file' onChange={e => {
                                                                const file = handleImageUpload(e);
                                                                if (file) {
                                                                    const updatedBannerMedia = bannerMedia.map((item, i) =>
                                                                        bann.index === item.index ? { ...item, vtImage: file.file, vtImageURL: file.url, vtImagePT: file.path } : item
                                                                    );
                                                                    setBannerMedia(updatedBannerMedia)
                                                                }

                                                            }} />
                                                            <label htmlFor={`vtimg-${bann.index}`} className='dropzone-area py-1 d-flex flex-column justify-content-center position-relative' style={{ height: 180 }} >
                                                                <div className='position-absolute' style={{ right: 10, top: 20 }}>
                                                                    <Button variant="link" className="text-secondary fw-medium p-0 me-3" type="button" onClick={e => {
                                                                        const updatedBannerMedia = bannerMedia.map((item, i) =>
                                                                            bann.index === item.index ? { ...item, vtImage: null, vtImageURL: null, vtImagePT: null } : item
                                                                        );
                                                                        setBannerMedia(updatedBannerMedia)
                                                                    }}>
                                                                        <FiDelete className='text-danger' />
                                                                    </Button>
                                                                </div>
                                                                <div className="mb-2">
                                                                    <img
                                                                        className="rounded me-2"
                                                                        width={80}
                                                                        style={{ maxHeight: 80 }}
                                                                        src={bann.vtImageURL}
                                                                    />
                                                                    {bann.vtImagePT}
                                                                </div>
                                                                <Flex justifyContent='center mt-4'>
                                                                    <p className="fs-0 mb-0 text-700 me-1">Select your file here</p>
                                                                    <img src={cloudUpload} alt="" width={25} className="me-2" />
                                                                </Flex>
                                                            </label>


                                                            <Row className='mt-3'>
                                                                <Col md={6}>
                                                                    <Form.Label>Height:</Form.Label>
                                                                    <FormControl type="number" value={bann.vdHeight} onChange={e => {
                                                                        const updatedBannerMedia = bannerMedia.map((item, i) =>
                                                                            bann.index === item.index ? { ...item, vdHeight: e.target.value } : item
                                                                        );
                                                                        setBannerMedia(updatedBannerMedia)
                                                                    }} />

                                                                    <Form.Label>Width:</Form.Label>
                                                                    <FormControl type="number" value={bann.vdWidth} onChange={e => {
                                                                        const updatedBannerMedia = bannerMedia.map((item, i) =>
                                                                            bann.index === item.index ? { ...item, vdWidth: e.target.value } : item
                                                                        );
                                                                        setBannerMedia(updatedBannerMedia)
                                                                    }} />

                                                                </Col>
                                                                <Col md={6}>
                                                                    <Form.Check
                                                                        type='checkbox'
                                                                        checked={bann.vAutoPlay} onChange={e => {
                                                                            const updatedBannerMedia = bannerMedia.map((item, i) =>
                                                                                bann.index === item.index ? { ...item, vAutoPlay: e.target.checked } : item
                                                                            );
                                                                            setBannerMedia(updatedBannerMedia)
                                                                        }}
                                                                        label='Auto Play'
                                                                    />
                                                                    <Form.Check
                                                                        type='checkbox'
                                                                        checked={bann.vControls} onChange={e => {
                                                                            const updatedBannerMedia = bannerMedia.map((item, i) =>
                                                                                bann.index === item.index ? { ...item, vControls: e.target.checked } : item
                                                                            );
                                                                            setBannerMedia(updatedBannerMedia)
                                                                        }}
                                                                        label='Controls'
                                                                    />
                                                                    <Form.Check
                                                                        type='checkbox'
                                                                        checked={bann.vLoop} onChange={e => {
                                                                            const updatedBannerMedia = bannerMedia.map((item, i) =>
                                                                                bann.index === item.index ? { ...item, vLoop: e.target.checked } : item
                                                                            );
                                                                            setBannerMedia(updatedBannerMedia)
                                                                        }}
                                                                        label='Loop'
                                                                    />
                                                                    <Form.Check
                                                                        type='checkbox'
                                                                        checked={bann.vMuted} onChange={e => {
                                                                            const updatedBannerMedia = bannerMedia.map((item, i) =>
                                                                                bann.index === item.index ? { ...item, vMuted: e.target.checked } : item
                                                                            );
                                                                            setBannerMedia(updatedBannerMedia)
                                                                        }}
                                                                        label='Muted'
                                                                    />
                                                                </Col>
                                                            </Row>

                                                        </>

                                                    }


                                                    <Form.Label className='mt-3'>Forground Image:</Form.Label>
                                                    <Row className='mb-3'>
                                                        <Col md={6}>
                                                            <Form.Label>Alt Text:</Form.Label>
                                                            <FormControl value={bann.fgAltText} onChange={e => {
                                                                const updatedBannerMedia = bannerMedia.map((item, i) =>
                                                                    bann.index === item.index ? { ...item, fgAltText: e.target.value } : item
                                                                );
                                                                setBannerMedia(updatedBannerMedia)
                                                            }} />
                                                        </Col>
                                                        <Col md={3}>
                                                            <Form.Label>Height:</Form.Label>
                                                            <FormControl type="number" value={bann.fgHeight} onChange={e => {
                                                                const updatedBannerMedia = bannerMedia.map((item, i) =>
                                                                    bann.index === item.index ? { ...item, fgHeight: e.target.value } : item
                                                                );
                                                                setBannerMedia(updatedBannerMedia)
                                                            }} />
                                                        </Col>
                                                        <Col md={3}>
                                                            <Form.Label>Width:</Form.Label>
                                                            <FormControl type="number" value={bann.fgWidth} onChange={e => {
                                                                const updatedBannerMedia = bannerMedia.map((item, i) =>
                                                                    bann.index === item.index ? { ...item, fgWidth: e.target.value } : item
                                                                );
                                                                setBannerMedia(updatedBannerMedia)
                                                            }} />
                                                        </Col>
                                                    </Row>
                                                    <input id={`fgimg-${bann.index}`} className='d-none' type='file' onChange={e => {
                                                        const file = handleImageUpload(e);
                                                        if (file) {
                                                            const updatedBannerMedia = bannerMedia.map((item, i) =>
                                                                bann.index === item.index ? { ...item, fgImage: file.file, fgImageURL: file.url, fgImagePT: file.path } : item
                                                            );
                                                            setBannerMedia(updatedBannerMedia)
                                                        }

                                                    }} />
                                                    <label htmlFor={`fgimg-${bann.index}`} className='dropzone-area py-1 d-flex flex-column justify-content-center position-relative' style={{ height: 180 }} >
                                                        <div className='position-absolute' style={{ right: 10, top: 20 }}>
                                                            <Button variant="link" className="text-secondary fw-medium p-0 me-3" type="button" onClick={e => {
                                                                const updatedBannerMedia = bannerMedia.map((item, i) =>
                                                                    bann.index === item.index ? { ...item, fgImage: null, fgImageURL: null, fgImagePT: null } : item
                                                                );
                                                                setBannerMedia(updatedBannerMedia)
                                                            }}>
                                                                <FiDelete className='text-danger' />
                                                            </Button>
                                                        </div>
                                                        <div className="mb-2">
                                                            <img
                                                                className="rounded me-2"
                                                                width={80}
                                                                style={{ maxHeight: 80 }}
                                                                src={bann.fgImageURL}
                                                            />
                                                            {bann.fgImagePT}
                                                        </div>
                                                        <Flex justifyContent='center mt-4'>
                                                            <p className="fs-0 mb-0 text-700 me-1">Select your file here</p>
                                                            <img src={cloudUpload} alt="" width={25} className="me-2" />
                                                        </Flex>
                                                    </label>


                                                </div>


                                            </Accordion.Body>

                                        </Accordion.Item>

                                    ))
                                    }
                                </Accordion>


                                <div className='d-flex justify-content-center mt-2'>
                                    <Button onClick={handleAddMedia}>
                                        Add another media
                                    </Button>
                                </div>


                            </Card.Body>
                        </Card>



                    </Col>
                    <Col lg={4}>
                        <div className="sticky-sidebar">
                            {/* <BannerPositions /> */}


                            <Card className="mb-3">
                                <Card.Header as="h6" className="bg-light">
                                    Alignments & Status
                                </Card.Header>
                                <Card.Body>
                                    <Row className="gx-2 gy-3">
                                        <Col md="12">
                                            <Form.Group>
                                                <Form.Label>Align Content:</Form.Label>
                                                <Form.Select value={bannerData.alignTitle} onChange={e => setBannerData({ ...bannerData, alignTitle: e.target.value })}>
                                                    <option value="center">Center</option>
                                                    <option value="left">Left</option>
                                                    <option value="right">Right</option>
                                                    <option value="default">Default</option>
                                                </Form.Select>
                                            </Form.Group>
                                        </Col>
                                        <Col md="12">
                                            <Form.Group>
                                                <Form.Check
                                                    type='checkbox'
                                                    checked={bannerData.showIndex} onChange={e => setBannerData({ ...bannerData, showIndex: e.target.checked })}
                                                    label='Show on Home Page'
                                                />
                                            </Form.Group>
                                        </Col>
                                        <Col md="12">
                                            <Form.Check
                                                type='checkbox'
                                                checked={bannerData.showHeader} onChange={e => setBannerData({ ...bannerData, showHeader: e.target.checked })}
                                                label='Show Banner on project Page'
                                            />
                                        </Col>
                                        <Col md="12">
                                            <Form.Group>
                                                <Form.Label>Status:</Form.Label>
                                                <Form.Select value={bannerData.status} onChange={e => setBannerData({ ...bannerData, status: e.target.value === "true" })}>
                                                    <option value="true">Active</option>
                                                    <option value="false">In-Active</option>
                                                </Form.Select>
                                            </Form.Group>
                                        </Col>
                                    </Row>
                                </Card.Body>
                            </Card>


                            <Card className="mb-3">
                                <Card.Header as="h6" className="bg-light">
                                    Meta Settings
                                </Card.Header>
                                <Card.Body>
                                    <Row className="gx-2 gy-3">
                                        <Col md="12">
                                            <Form.Group>
                                                <Form.Label>Meta Description:</Form.Label>
                                                <FormControl as="textarea" rows={4} value={bannerData.metaDesc} onChange={e => setBannerData({ ...bannerData, metaDesc: e.target.value })} />
                                            </Form.Group>
                                        </Col>
                                        <Col md="12">
                                            <Form.Group>
                                                <Form.Label>Meta Keywords:</Form.Label>
                                                <FormControl as="textarea" rows={4} value={bannerData.metaTags} onChange={e => setBannerData({ ...bannerData, metaTags: e.target.value })} />
                                            </Form.Group>
                                        </Col>
                                    </Row>
                                </Card.Body>
                            </Card>


                        </div>
                    </Col>
                    <Col>

                        <Card>
                            <Card.Body>
                                <Row className="flex-between-center">
                                    <Col md>
                                        <h5 className="mb-2 mb-md-0">You're almost done</h5>
                                    </Col>
                                    <Col xs="auto">
                                        <Link to={'/projects'}>
                                            <Button
                                                variant="link"
                                                className="text-secondary fw-medium p-0 me-3"
                                                type="button"
                                            >
                                                Discard
                                            </Button>
                                        </Link>
                                        <Button variant="primary" type="submit">
                                            Update project
                                        </Button>
                                    </Col>
                                </Row>
                            </Card.Body>
                        </Card>

                    </Col>
                </Row>
            </Form>
        </FormProvider>
    );
};

export default UpdateProject;
