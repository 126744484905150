import React, { useEffect, useState } from 'react';
import { Button, Card, Dropdown, Modal, OverlayTrigger, Tooltip } from 'react-bootstrap';
import CardDropdown from 'components/common/CardDropdown';
import { Link, useNavigate } from 'react-router-dom';
import Flex from 'components/common/Flex';
import Avatar from 'components/common/Avatar';
import AdvanceTableWrapper from 'components/common/advance-table/AdvanceTableWrapper';
import CustomersTableHeader from './CustomersTableHeader';
import AdvanceTablePagination from 'components/common/advance-table/AdvanceTablePagination';
import AdvanceTable from 'components/common/advance-table/AdvanceTable';
import { DeleteBanner, DeleteSCateg, DeleteTCateg, GetBanners, GetSCategories, GetTCategories } from 'apis/endpoints';
import { useSelector } from 'react-redux';
import SoftBadge from 'components/common/SoftBadge';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import { FiAlertTriangle, FiDelete, FiEdit, FiEdit2, FiEdit3, FiTrash, FiTrash2 } from 'react-icons/fi';
import { toast } from 'react-toastify';








const TeamCategories = () => {
    const [sCategs, setSCategs] = useState([]);

    const agencyID = useSelector(state => state.MEIUser.agencyID)



    const getSCategs = () => {

        GetTCategories(agencyID).then(res => {

            setSCategs(res.data);

        }).catch(err => {

        })

    }


    useEffect(() => {

        getSCategs();


    }, [agencyID]);




    const [deleteConfirmModal, setDeleteConfirmModal] = useState(false);
    const [deleteingName, setDeletingName] = useState({});


    function handleConfirmDeleteBanner() {

        DeleteTCateg(deleteingName.id).then(res => {
            setDeleteConfirmModal(false)
            getSCategs()
            toast.success(`Category deleted successfully!`, {
                theme: 'colored'
            });
        }).catch(err => {
            console.log(err)
            setDeleteConfirmModal(false)
            toast.error('Category delete failed!, Because it is used as parent category in some other category.', {
                theme: 'colored'
            });
        })
    }

    const columns = [
        {
            accessor: 'action-ed',
            Header: '',
            disableSortBy: true,
            cellProps: {
                className: 'text-start',
                style: { width: 120 }
            },

            Cell: (rowData) => {
                const navigate = useNavigate();


                function handleEditBanner() {
                    navigate('/team-categories/update', { state: { scateg_id: rowData.row.original.id } })
                }

                function handleDeleteBanner() {
                    setDeletingName({name: rowData.row.original.name, id: rowData.row.original.id})
                    setDeleteConfirmModal(true)
                }

                return (
                    <>



                        <OverlayTrigger placement='right' overlay={
                            <Tooltip>
                                View/Edit
                            </Tooltip>
                        }>
                            <Button variant='falcon-primary' className='me-1' size='sm' onClick={e => handleEditBanner()}>
                                <FiEdit />
                            </Button>
                        </OverlayTrigger>
                        <OverlayTrigger placement='right' overlay={
                            <Tooltip>
                                Delete
                            </Tooltip>
                        }>
                            <Button variant='falcon-danger' className='' size='sm' onClick={e => handleDeleteBanner()}>
                                <FiTrash2 />
                            </Button>
                        </OverlayTrigger>
                    </>
                );
            }
        },
        {
            accessor: 'name',
            Header: 'Name',
            headerProps: { className: 'pe-1' },
            cellProps: {
                className: 'py-2'
            },
            Cell: rowData => {
                const { name, } = rowData.row.original;
                return (
                    // <Link to="/e-commerce/customer-details">
                        <Flex alignItems="center">
                            <div className="flex-1">
                                <h5 className="mb-0 fs--1">{name}</h5>
                            </div>
                        </Flex>
                    // </Link>
                );
            }
        },
        {
            accessor: 'parent',
            Header: 'Parent',
            headerProps: { className: 'pe-1' },
            cellProps: {
                className: 'py-2'
            },
            Cell: rowData => {
                const { parent_name, } = rowData.row.original;
                return (
                    <Link to="/e-commerce/customer-details">
                        <Flex alignItems="center">
                            <div className="flex-1">
                                <h5 className="mb-0 fs--1">{parent_name}</h5>
                            </div>
                        </Flex>
                    </Link>
                );
            }
        },
        // {
        //     accessor: 'position',
        //     Header: 'Position',
        //     headerProps: { className: 'pe-1' },
        //     cellProps: {
        //         className: 'py-2'
        //     },
        //     Cell: rowData => {
        //         const { position, } = rowData.row.original;
        //         return (
        //             <Link to="/e-commerce/customer-details">
        //                 <Flex alignItems="center">
        //                     <div className="flex-1">
        //                         <h5 className="mb-0 fs--1">{position}</h5>
        //                     </div>
        //                 </Flex>
        //             </Link>
        //         );
        //     }
        // },


        {
            accessor: 'status',
            Header: 'Status',
            headerProps: {
                className: 'text-center'
            },
            cellProps: {
                className: 'fs-0',
                style: { width: 130 }
            },
            Cell: rowData => {
                const { status } = rowData.row.original;
                return (
                    <SoftBadge
                        pill
                        bg={classNames({
                            success: status === true,
                            danger: status === false,
                        })}
                        className="d-flex flex-center w-fit-content"
                        style={{ width: 'auto' }}
                    >
                        <FontAwesomeIcon
                            icon={classNames({
                                check: status,
                                times: !status,
                            })}
                            transform="shrink-2"
                            className="me-2"
                        />
                        <p className="mb-0">
                            {status ? 'Active' : 'In-Active'}
                        </p>
                    </SoftBadge>
                );
            }
        },

    ];



    return (
        <>

            <Modal show={deleteConfirmModal} backdrop="static" onHide={() => setDeleteConfirmModal(false)} keyboard={false} centered>

                <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-vcenter" className='text-danger '>
                        <FiAlertTriangle className='me-2' />
                        Are you sure?
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>

                    <div className=' text-danger'>
                        {deleteingName.name} will be deleted permanently!
                    </div>
                    <div className=' d-flex justify-content-center mt-3'>

                        <Button variant='falcon-primary' className='me-3' size='sm' onClick={e => setDeleteConfirmModal(false)}>
                            Cancel
                        </Button>
                        <Button variant='falcon-danger' className='' size='sm' onClick={e => handleConfirmDeleteBanner()}>
                            Delete
                        </Button>


                    </div>

                </Modal.Body>

            </Modal>

            <AdvanceTableWrapper
                columns={columns}
                data={sCategs}
                // selection
                sortable
                pagination
                perPage={100}
            >
                <Card className="mb-3">
                    <Card.Header>
                        <CustomersTableHeader table />
                    </Card.Header>
                    <Card.Body className="p-0">
                        <AdvanceTable
                            table
                            headerClassName="bg-200 text-900 text-nowrap align-middle"
                            rowClassName="align-middle white-space-nowrap"
                            tableProps={{
                                size: 'sm',
                                striped: true,
                                className: 'fs--1 mb-0 overflow-hidden'
                            }}
                        />
                    </Card.Body>
                    <Card.Footer>
                        <AdvanceTablePagination table />
                    </Card.Footer>
                </Card>
            </AdvanceTableWrapper>
        </>
    );
};

export default TeamCategories;
