import React, { useEffect, useState } from 'react';
import ProfileBanner from './Banner';
import { Card, Col, Form, FormControl, InputGroup, Row, Button, Modal, Spinner } from 'react-bootstrap';
import { FormProvider } from 'react-hook-form';

import cloudUpload from 'assets/img/icons/cloud-upload.svg';

import { toast } from 'react-toastify';
import Flex from 'components/common/Flex';
import { useSelector } from 'react-redux';
import { AgencyGetProfile, AgencyPostProfile } from 'apis/endpoints';
import { base_url } from 'apis/constants';

const Profile = () => {

  const agencyId = useSelector(state => state.MEIUser.agencyID);


  const [loadingModal, setLoadingModal] = useState(false);
  const [profile, setProfile] = useState({});

  const [profileData, setProfileData] = useState({});



  const GetProfile = () => {
    AgencyGetProfile(agencyId).then(res => {

      setProfile(res.data)

      setProfileData({
        phone: res.data.phone,
        waphone: res.data.wa_phone,
        showPNav: res.data.p_nav,
        showPFoot: res.data.p_footer,

        email: res.data.email,
        contactEmail: res.data.contact_email,
        contactEmailSameAS: (res.data.email === res.data.contact_email),
        showENav: res.data.e_nav,
        showEFoot: res.data.e_footer,

        address: res.data.address,

        metaDesc: res.data.meta_description,
        metaTags: res.data.meta_keywords,


        repeatBuiseness: res.data.repeat_business || '0',
        startingYear: res.data.starting_year,

        gmap: res.data.gmap,

        facebook: res.data.facebook,
        twitter: res.data.twitter,
        instagram: res.data.instagram,
        linkedin: res.data.linkedin,
        youtube: res.data.youtube,


        favicon: null,
        faviconURL: res.data.favicon ? `${base_url}${res.data.favicon}` : null,
        faviconPT: null,

        footerbg: null,
        footerbgURL: res.data.footerbg ? `${base_url}${res.data.footerbg}` : null,
        footerbgPT: null,

        navlight: null,
        navlightURL: res.data.logo_h_light ? `${base_url}${res.data.logo_h_light}` : null,
        navlightPT: null,

        navdark: null,
        navdarkURL: res.data.logo_h_dark ? `${base_url}${res.data.logo_h_dark}` : null,
        navdarkPT: null,

        footlight: null,
        footlightURL: res.data.logo_f_light ? `${base_url}${res.data.logo_f_light}` : null,
        footlightPT: null,

        footdark: null,
        footdarkURL: res.data.logo_f_dark ? `${base_url}${res.data.logo_f_dark}` : null,
        footdarkPT: null,



      })


    }).catch(err => {
      console.log(err)
    })
  }


  useEffect(() => {
    GetProfile();

  }, [agencyId])





  const handleSubmitProfile = (e) => {
    e.preventDefault();



    setLoadingModal(true);

    const finalFormData = new FormData();

    finalFormData.append('e_nav', profileData.showENav)
    finalFormData.append('e_footer', profileData.showEFoot)
    finalFormData.append('contact_email', profileData.contactEmail)
    finalFormData.append('phone', profileData.phone)
    finalFormData.append('wa_phone', profileData.waphone)
    finalFormData.append('p_nav', profileData.showPNav)
    finalFormData.append('p_footer', profileData.showPFoot)
    finalFormData.append('address', profileData.address)
    finalFormData.append('facebook', profileData.facebook)
    finalFormData.append('twitter', profileData.twitter)
    finalFormData.append('instagram', profileData.instagram)
    finalFormData.append('linkedin', profileData.linkedin)
    finalFormData.append('youtube', profileData.youtube)
    finalFormData.append('gmap', profileData.gmap)
    finalFormData.append('meta_description', profileData.metaDesc)
    finalFormData.append('meta_keywords', profileData.metaTags)

    finalFormData.append('repeat_business', profileData.repeatBuiseness)
    finalFormData.append('starting_year', profileData.startingYear)

    if (profileData.favicon)
      finalFormData.append('favicon', profileData.favicon)

    if (profileData.footerbg)
      finalFormData.append('footerbg', profileData.footerbg)

    if (profileData.navlight)
      finalFormData.append('logo_h_light', profileData.navlight)

    if (profileData.navdark)
      finalFormData.append('logo_h_dark', profileData.navdark)

    if (profileData.footlight)
      finalFormData.append('logo_f_light', profileData.footlight)

    if (profileData.footdark)
      finalFormData.append('logo_f_dark', profileData.footdark)



    AgencyPostProfile(agencyId, finalFormData).then(res => {
      setLoadingModal(false);
      toast.success(`Agency profile updated successfully!`, {
        theme: 'colored'
      });
      GetProfile();

    }).catch(err => {
      const errMsg = Object.values(err?.response?.data)[0][0];
      setLoadingModal(false);
      toast.error(errMsg || 'Profile update failed!', {
        theme: 'colored'
      });
    });



  };



  const handleImageUpload = (event) => {
    const file = event.target.files[0];
    const imageType = /^(?!.*\.webp$)image.*/;

    if (!file?.type?.match(imageType)) {
      toast.error('Only image files are allowed', {
        theme: 'colored'
      });
      return null;
    }
    if (file?.type?.match(imageType)) {
      return { file: file, url: URL.createObjectURL(file), path: file.name }
    }
  };

  const currentYear = new Date().getFullYear();
  const yearsList = [];
  for (let year = 1975; year <= currentYear; year++) {
    yearsList.unshift(year);
  }



  return (
    <>
      <ProfileBanner profile={profile} />


      <FormProvider>
        <Modal show={loadingModal} backdrop="static" keyboard={false} centered>

          <Modal.Body>

            <div className='d-flex justify-content-center'>
              <Spinner animation="border" variant="info" size='lg' />
            </div>

          </Modal.Body>

        </Modal>
        <Form onSubmit={handleSubmitProfile}>
          <Row className="g-3">
            <Col xs={12}>
              <Card>
                <Card.Body>
                  <Row className="flex-between-center">
                    <Col md>
                      <h5 className="mb-2 mb-md-0">Setup your agency profile here</h5>
                    </Col>
                    <Col xs="auto">
                      <Button variant="primary" type="submit">
                        Update
                      </Button>
                    </Col>
                  </Row>
                </Card.Body>
              </Card>
            </Col>
            <Col lg={8}>

              <Card className="mb-3">
                <Card.Header as="h6" className="bg-light">
                  Basic information
                </Card.Header>
                <Card.Body>
                  <Row className="gx-2 gy-3">
                    <Col md="12">
                      <Form.Group>
                        <Form.Label>Email:</Form.Label>
                        <Flex alignItems={'center'} justifyContent={'between'}>
                          <label className='small'>Show in topbar</label>
                          <label className='small'>Show in footer</label>
                        </Flex>
                        <InputGroup className="mb-1">
                          <InputGroup.Checkbox checked={profileData.showENav} onChange={e => setProfileData({ ...profileData, showENav: e.target.checked })} />
                          <FormControl value={profileData.email} disabled onChange={e => setProfileData({ ...profileData, email: e.target.value })} />
                          <InputGroup.Checkbox checked={profileData.showEFoot} onChange={e => setProfileData({ ...profileData, showEFoot: e.target.checked })} />
                        </InputGroup>
                      </Form.Group>
                    </Col>
                    <Col md="12">
                      <Form.Group>
                        <Form.Label>Inquirey Email:</Form.Label>
                        <Flex alignItems={'center'} justifyContent={'between'}>
                          <label className='small'>Same as above</label>
                        </Flex>
                        <InputGroup className="mb-1">
                          <InputGroup.Checkbox checked={profileData.contactEmailSameAS} onChange={e => {
                            if (e.target.checked) {
                              setProfileData({ ...profileData, contactEmail: profileData.email, contactEmailSameAS: e.target.checked });
                            }
                            else {
                              setProfileData({ ...profileData, contactEmail: '', contactEmailSameAS: e.target.checked });
                            }
                          }} />
                          <FormControl value={profileData.contactEmail} onChange={e => setProfileData({ ...profileData, contactEmail: e.target.value })} required disabled={profileData.contactEmailSameAS} title='Email address where you will recieve public quries.' />
                        </InputGroup>
                      </Form.Group>
                    </Col>
                    <Col md="12">
                      <Form.Group>
                        <Form.Label>Phone Number:</Form.Label>
                        <Flex alignItems={'center'} justifyContent={'between'}>
                          <label className='small'>Show in topbar</label>
                          <label className='small'>Show in footer</label>
                        </Flex>
                        <InputGroup className="mb-1">
                          <InputGroup.Checkbox checked={profileData.showPNav} onChange={e => setProfileData({ ...profileData, showPNav: e.target.checked })} />
                          <FormControl value={profileData.phone} onChange={e => setProfileData({ ...profileData, phone: e.target.value })} required />
                          <InputGroup.Checkbox checked={profileData.showPFoot} onChange={e => setProfileData({ ...profileData, showPFoot: e.target.checked })} />
                        </InputGroup>
                      </Form.Group>
                    </Col>

                    <Col md="12">
                      <Form.Group>
                        <Form.Label>Whatsapp Number:</Form.Label>

                        <InputGroup className="mb-1">
                          <FormControl value={profileData.waphone} onChange={e => setProfileData({ ...profileData, waphone: e.target.value })} placeholder='+920000000000' />
                        </InputGroup>
                      </Form.Group>
                    </Col>


                    <Col md="12">
                      <Form.Group>
                        <Form.Label>Address:</Form.Label>
                        <FormControl as={'textarea'} rows={4} value={profileData.address} onChange={e => setProfileData({ ...profileData, address: e.target.value })} />
                      </Form.Group>
                    </Col>



                  </Row>
                </Card.Body>
              </Card>



              <Card className="mb-3">
                <Card.Header as="h6" className="bg-light">
                  Logos & Icon
                </Card.Header>
                <Card.Body>
                  <Row>
                    <Col md={12}>
                      <Form.Label>Favicon:</Form.Label>
                      <input id='img-favicon' className='d-none' type='file' onChange={e => {
                        const file = handleImageUpload(e);
                        if (file) {
                          setTimeout(() => {
                            setProfileData({ ...profileData, favicon: file.file, faviconURL: file.url, faviconPT: file.path })
                          }, 1);
                        }

                      }} />
                      <label htmlFor='img-favicon' className='dropzone-area py-1 d-flex flex-column justify-content-center position-relative' style={{ height: 180 }} >
                        <div className="mb-2">
                          <img
                            className="rounded me-2"
                            width={80}
                            style={{ maxHeight: 80 }}
                            src={profileData.faviconURL}
                          />
                          {profileData.faviconPT}
                        </div>
                        <Flex justifyContent='center mt-4'>
                          <p className="fs-0 mb-0 text-700 me-1">Select your file here</p>
                          <img src={cloudUpload} alt="" width={25} className="me-2" />
                        </Flex>
                      </label>
                    </Col>

                    <Form.Label className='mt-2'>Nav Bar Logo:</Form.Label>
                    <Col md={6}>
                      <Form.Label>Light:</Form.Label>
                      <input id='img-navlight' className='d-none' type='file' onChange={e => {
                        const file = handleImageUpload(e);
                        if (file) {
                          setTimeout(() => {
                            setProfileData({ ...profileData, navlight: file.file, navlightURL: file.url, navlightPT: file.path })
                          }, 1);
                        }

                      }} />
                      <label htmlFor='img-navlight' className='dropzone-area py-1 d-flex flex-column justify-content-center position-relative' style={{ height: 180 }} >
                        <div className="mb-2">
                          <img
                            className="rounded me-2"
                            width={80}
                            style={{ maxHeight: 80 }}
                            src={profileData.navlightURL}
                          />
                          {profileData.navlightPT}
                        </div>
                        <Flex justifyContent='center mt-4'>
                          <p className="fs-0 mb-0 text-700 me-1">Select your file here</p>
                          <img src={cloudUpload} alt="" width={25} className="me-2" />
                        </Flex>
                      </label>
                    </Col>
                    <Col md={6}>
                      <Form.Label>Dark:</Form.Label>
                      <input id='img-navdark' className='d-none' type='file' onChange={e => {
                        const file = handleImageUpload(e);
                        if (file) {
                          setTimeout(() => {
                            setProfileData({ ...profileData, navdark: file.file, navdarkURL: file.url, navdarkPT: file.path })
                          }, 1);
                        }

                      }} />
                      <label htmlFor='img-navdark' className='dropzone-area py-1 d-flex flex-column justify-content-center position-relative' style={{ height: 180 }} >
                        <div className="mb-2">
                          <img
                            className="rounded me-2"
                            width={80}
                            style={{ maxHeight: 80 }}
                            src={profileData.navdarkURL}
                          />
                          {profileData.navdarkPT}
                        </div>
                        <Flex justifyContent='center mt-4'>
                          <p className="fs-0 mb-0 text-700 me-1">Select your file here</p>
                          <img src={cloudUpload} alt="" width={25} className="me-2" />
                        </Flex>
                      </label>
                    </Col>

                    <Form.Label className='mt-2'>Footer Logo:</Form.Label>
                    <Col md={6}>
                      <Form.Label>Light:</Form.Label>
                      <input id='img-footlight' className='d-none' type='file' onChange={e => {
                        const file = handleImageUpload(e);
                        if (file) {
                          setTimeout(() => {
                            setProfileData({ ...profileData, footlight: file.file, footlightURL: file.url, footlightPT: file.path })
                          }, 1);
                        }

                      }} />
                      <label htmlFor='img-footlight' className='dropzone-area py-1 d-flex flex-column justify-content-center position-relative' style={{ height: 180 }} >
                        <div className="mb-2">
                          <img
                            className="rounded me-2"
                            width={80}
                            style={{ maxHeight: 80 }}
                            src={profileData.footlightURL}
                          />
                          {profileData.footlightPT}
                        </div>
                        <Flex justifyContent='center mt-4'>
                          <p className="fs-0 mb-0 text-700 me-1">Select your file here</p>
                          <img src={cloudUpload} alt="" width={25} className="me-2" />
                        </Flex>
                      </label>
                    </Col>
                    <Col md={6}>
                      <Form.Label>Dark:</Form.Label>
                      <input id='img-footdark' className='d-none' type='file' onChange={e => {
                        const file = handleImageUpload(e);
                        if (file) {
                          setTimeout(() => {
                            setProfileData({ ...profileData, footdark: file.file, footdarkURL: file.url, footdarkPT: file.path })
                          }, 1);
                        }

                      }} />
                      <label htmlFor='img-footdark' className='dropzone-area py-1 d-flex flex-column justify-content-center position-relative' style={{ height: 180 }} >
                        <div className="mb-2">
                          <img
                            className="rounded me-2"
                            width={80}
                            style={{ maxHeight: 80 }}
                            src={profileData.footdarkURL}
                          />
                          {profileData.footdarkPT}
                        </div>
                        <Flex justifyContent='center mt-4'>
                          <p className="fs-0 mb-0 text-700 me-1">Select your file here</p>
                          <img src={cloudUpload} alt="" width={25} className="me-2" />
                        </Flex>
                      </label>
                    </Col>

                    <Col md={12}>
                      <Form.Label>Footer Background:</Form.Label>
                      <input id='img-footerbg' className='d-none' type='file' onChange={e => {
                        const file = handleImageUpload(e);
                        if (file) {
                          setTimeout(() => {
                            setProfileData({ ...profileData, footerbg: file.file, footerbgURL: file.url, footerbgPT: file.path })
                          }, 1);
                        }

                      }} />
                      <label htmlFor='img-footerbg' className='dropzone-area py-1 d-flex flex-column justify-content-center position-relative' style={{ height: 180 }} >
                        <div className="mb-2">
                          <img
                            className="rounded me-2"
                            width={80}
                            style={{ maxHeight: 80 }}
                            src={profileData.footerbgURL}
                          />
                          {profileData.footerbgPT}
                        </div>
                        <Flex justifyContent='center mt-4'>
                          <p className="fs-0 mb-0 text-700 me-1">Select your file here</p>
                          <img src={cloudUpload} alt="" width={25} className="me-2" />
                        </Flex>
                      </label>
                    </Col>

                  </Row>




                </Card.Body>
              </Card>


              <Card className="mb-3">
                <Card.Header as="h6" className="bg-light">
                  Meta Tags
                </Card.Header>
                <Card.Body>
                  <Row className="gx-2 gy-3">

                    <Col md="12">
                      <Form.Group>
                        <Form.Label>Meta Description:</Form.Label>
                        <FormControl as={'textarea'} rows={6} value={profileData.metaDesc} onChange={e => setProfileData({ ...profileData, metaDesc: e.target.value })} />
                      </Form.Group>
                    </Col>

                    <Col md="12">
                      <Form.Group>
                        <Flex alignItems={''}>
                          <Form.Label>Meta Keywords:</Form.Label> <p className='ms-2 small text-warning'>(comma separated)</p>
                        </Flex>
                        <FormControl as={'textarea'} rows={6} value={profileData.metaTags} onChange={e => setProfileData({ ...profileData, metaTags: e.target.value })} />
                      </Form.Group>
                    </Col>

                  </Row>
                </Card.Body>
              </Card>






            </Col>
            <Col lg={4}>
              <div className="sticky-sidebar">
                {/* <BannerPositions /> */}



                <Card className="mb-3">
                  <Card.Header as="h6" className="bg-light">
                    Buiseness Details
                  </Card.Header>
                  <Card.Body>
                    <Row className="gx-2 gy-3">
                      <Col md="12">
                        <Form.Group>
                          <Form.Label>Started Since:</Form.Label>
                          <Form.Select value={profileData.startingYear} onChange={e => setProfileData({ ...profileData, startingYear: e.target.value })}>
                            <option value="">----</option>
                            {yearsList.map((pr) => (
                              <option value={pr}>{pr}</option>
                            ))
                            }
                          </Form.Select>
                        </Form.Group>
                      </Col>

                      <Col md="12">
                        <Form.Group>
                          <Form.Label>Repeat Business (%):</Form.Label>
                          <FormControl value={profileData.repeatBuiseness} onChange={e => setProfileData({ ...profileData, repeatBuiseness: e.target.value })} type='number' required max={100} min={0} />
                        </Form.Group>
                      </Col>

                    </Row>
                  </Card.Body>
                </Card>


                <Card className="mb-3">
                  <Card.Header as="h6" className="bg-light">
                    Social Media Links
                  </Card.Header>
                  <Card.Body>
                    <Row className="gx-2 gy-3">
                      <Col md="12">
                        <Form.Group>
                          <Form.Label>Facebook:</Form.Label>
                          <FormControl value={profileData.facebook} onChange={e => setProfileData({ ...profileData, facebook: e.target.value })} type='url' />
                        </Form.Group>
                      </Col>
                      <Col md="12">
                        <Form.Group>
                          <Form.Label>Twitter:</Form.Label>
                          <FormControl value={profileData.twitter} onChange={e => setProfileData({ ...profileData, twitter: e.target.value })} type='url' />
                        </Form.Group>
                      </Col>
                      <Col md="12">
                        <Form.Group>
                          <Form.Label>Instagram:</Form.Label>
                          <FormControl value={profileData.instagram} onChange={e => setProfileData({ ...profileData, instagram: e.target.value })} type='url' />
                        </Form.Group>
                      </Col>
                      <Col md="12">
                        <Form.Group>
                          <Form.Label>LinkedIn:</Form.Label>
                          <FormControl value={profileData.linkedin} onChange={e => setProfileData({ ...profileData, linkedin: e.target.value })} type='url' />
                        </Form.Group>
                      </Col>
                      <Col md="12">
                        <Form.Group>
                          <Form.Label>Youtube:</Form.Label>
                          <FormControl value={profileData.youtube} onChange={e => setProfileData({ ...profileData, youtube: e.target.value })} type='url' />
                        </Form.Group>
                      </Col>

                    </Row>
                  </Card.Body>
                </Card>




                <Card className="mb-3">
                  <Card.Header as="h6" className="bg-light">
                    Google Map
                  </Card.Header>
                  <Card.Body>
                    <Row className="gx-2 gy-3">
                      <Col md="12">
                        <Form.Group>
                          <FormControl as={'textarea'} rows={6} value={profileData.gmap} onChange={e => setProfileData({ ...profileData, gmap: e.target.value })} />
                        </Form.Group>
                      </Col>
                    </Row>
                  </Card.Body>
                </Card>


              </div>
            </Col>
            <Col>
              <Card>
                <Card.Body>
                  <Row className="flex-between-center">
                    <Col md>
                      <h5 className="mb-2 mb-md-0">You're almost done</h5>
                    </Col>
                    <Col xs="auto">

                      <Button variant="primary" type="submit">
                        Update
                      </Button>
                    </Col>
                  </Row>
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </Form>
      </FormProvider>

    </>
  );
};

export default Profile;
