import React, { useEffect, useState } from 'react';
import { Card, Col, Form, FormControl, InputGroup, Row, Button, Accordion, Modal, Spinner, FormSelect } from 'react-bootstrap';
import { FormProvider } from 'react-hook-form';


import cloudUpload from 'assets/img/icons/cloud-upload.svg';




import Flex from 'components/common/Flex';

import { useSelector } from 'react-redux';
import { GetIndustrys, GetProjects, GetServices, PostClient, UpdatePostClient } from 'apis/endpoints';

import { FiDelete } from 'react-icons/fi';
import { toast } from 'react-toastify';
import { Link, useNavigate } from 'react-router-dom';
import MEICKEditor from 'components/mei/components/MEICKEditor';
import Select from 'components/doc-components/Select';
import MultiSelect from 'components/common/MultiSelect';
import { countryNamesList } from 'apis/constants';



const AddClients = () => {



  const agencyID = useSelector(state => state.MEIUser.agencyID);


  const navigate = useNavigate();

  const [loadingModal, setLoadingModal] = useState(false);


  const [bannerData, setBannerData] = useState({
    name: '',
    company: '',
    designation: '',
    department: '',
    phone: '',
    email: '',
    address: '',
    experience: '',
    skills: '',
    industry: '',
    origin: '',

    status: true,

    profile: null,
    profilePath: null,
    profileURL: null,

    services: [],
    projects: [],

  });

  const [availableServices, setAvailableServices] = useState([]);
  const [availableProjects, setAvailableProjects] = useState([]);
  const [availableIndustrys, setAvailableIndustrys] = useState([]);


  useEffect(() => {

    GetServices(agencyID).then(res => {
      setAvailableServices(res.data)
    });
    GetProjects(agencyID).then(res => {
      setAvailableProjects(res.data)
    });
    GetIndustrys().then(res => {
      setAvailableIndustrys(res.data)
    });

  }, [agencyID])


  const handleSubmitBanner = (e) => {
    e.preventDefault();

    setLoadingModal(true);

    const finalData = {
      agency: agencyID,
      name: bannerData.name,
      company: bannerData.company,
      industry: bannerData.industry,
      origin: bannerData.origin,
      designation: bannerData.designation,
      department: bannerData.department,
      phone: bannerData.phone,
      email: bannerData.email,
      address: bannerData.address,
      experience: bannerData.experience,
      skills: bannerData.skills,
      status: bannerData.status,
      services: bannerData.services.map(sr => (sr.value)),
      projects: bannerData.projects.map(sr => (sr.value)),
    }


    PostClient(finalData).then(res => {



      if (bannerData.profile) {
        const finalPData = new FormData();
        finalPData.append('profile', bannerData.profile)
        finalPData.append('agency', agencyID)

        UpdatePostClient(res.data.id, finalPData).then(res => {
          setLoadingModal(false);
          toast.success(`Client added successfully!`, {
            theme: 'colored'
          });
          navigate('/clients')

        }).catch(err => {
          const errMsg = Object.values(err?.response?.data)[0][0];
          setLoadingModal(false);
          toast.error(errMsg || 'Client add failed!', {
            theme: 'colored'
          });
        });

      }
      else {
        setLoadingModal(false);
        toast.success(`Client added successfully!`, {
          theme: 'colored'
        });
        navigate('/clients')
      }


    }).catch(err => {
      const errMsg = Object.values(err?.response?.data)[0][0];
      setLoadingModal(false);
      toast.error(errMsg || 'Client add failed!', {
        theme: 'colored'
      });
    });



  };


  const handleImageUpload = (event) => {
    const file = event.target.files[0];
    const imageType = /^(?!.*\.webp$)image.*/;

    if (!file?.type?.match(imageType)) {
      toast.error('Only image files are allowed', {
        theme: 'colored'
      });
      return null;
    }
    if (file?.type?.match(imageType)) {
      return { file: file, url: URL.createObjectURL(file), path: file.name }
    }
  };


  return (
    <FormProvider>
      <Modal show={loadingModal} backdrop="static" keyboard={false} centered>

        <Modal.Body>

          <div className='d-flex justify-content-center'>
            <Spinner animation="border" variant="info" size='lg' />
          </div>

        </Modal.Body>

      </Modal>
      <Form onSubmit={handleSubmitBanner}>
        <Row className="g-3">
          <Col xs={12}>
            <Card>
              <Card.Body>
                <Row className="flex-between-center">
                  <Col md>
                    <h5 className="mb-2 mb-md-0">Add a client</h5>
                  </Col>
                  <Col xs="auto">
                    <Link to={'/clients'}>
                      <Button
                        variant="link"
                        className="text-secondary fw-medium p-0 me-3"
                        type="button"
                      >
                        Discard
                      </Button>
                    </Link>
                    <Button variant="primary" type="submit">
                      Add client
                    </Button>
                  </Col>
                </Row>
              </Card.Body>
            </Card>
          </Col>
          <Col lg={8}>

            <Card className="mb-3">
              <Card.Header as="h6" className="bg-light">
                Basic information
              </Card.Header>
              <Card.Body>
                <Row className="gx-2 gy-3">
                  <Col md="12">
                    <Form.Group>
                      <Form.Label>Name:</Form.Label>
                      <InputGroup className="mb-1">
                        <FormControl value={bannerData.name} onChange={e => setBannerData({ ...bannerData, name: e.target.value })} required />
                      </InputGroup>
                    </Form.Group>
                  </Col>

                  <Col md="6">
                    <Form.Group>
                      <Form.Label>Phone:</Form.Label>
                      <FormControl value={bannerData.phone} onChange={e => setBannerData({ ...bannerData, phone: e.target.value })} />
                    </Form.Group>
                  </Col>
                  <Col md="6">
                    <Form.Group>
                      <Form.Label>Email:</Form.Label>
                      <FormControl value={bannerData.email} onChange={e => setBannerData({ ...bannerData, email: e.target.value })} type='email' />
                    </Form.Group>
                  </Col>

                  {/* <Col md="6">
                    <Form.Group>
                      <Form.Label>Designation:</Form.Label>
                      <InputGroup className="mb-1">
                        <FormControl value={bannerData.designation} onChange={e => setBannerData({ ...bannerData, designation: e.target.value })} />
                      </InputGroup>
                    </Form.Group>
                  </Col>

                  <Col md="6">
                    <Form.Group>
                      <Form.Label>Department:</Form.Label>
                      <InputGroup className="mb-1">
                        <FormControl value={bannerData.department} onChange={e => setBannerData({ ...bannerData, department: e.target.value })} />
                      </InputGroup>
                    </Form.Group>
                  </Col>
                  */}


                  <Col md="12">
                    <Form.Group>
                      <Form.Label>Industry:</Form.Label>
                      <Form.Select value={bannerData.industry} onChange={e => setBannerData({ ...bannerData, industry: e.target.value })}>
                        <option value="">----</option>
                        {availableIndustrys.map((pr) => (
                          <option value={pr.id}>{pr.name}</option>
                        ))
                        }
                      </Form.Select>
                    </Form.Group>
                  </Col>

                  <Col md="12">
                    <Form.Group>
                      <Form.Label>Company:</Form.Label>
                      <InputGroup className="mb-1">
                        <FormControl value={bannerData.company} onChange={e => setBannerData({ ...bannerData, company: e.target.value })} />
                      </InputGroup>
                    </Form.Group>
                  </Col>

                  
                  <Col md="12">
                    <Form.Group>
                      <Form.Label>Origin:</Form.Label>
                      <Form.Select value={bannerData.origin} onChange={e => setBannerData({ ...bannerData, origin: e.target.value })}>
                        <option value="">----</option>
                        {countryNamesList.map((pr) => (
                          <option value={pr}>{pr}</option>
                        ))
                        }
                      </Form.Select>
                    </Form.Group>
                  </Col>


                </Row>
              </Card.Body>
            </Card>


            <Card className="mb-3">
              <Card.Header as="h6" className="bg-light">
                Services & Projects
              </Card.Header>
              <Card.Body>
                <Row className="gx-2 gy-3">
                  <Col md="12">
                    <Form.Group>
                      <Form.Label>Services:</Form.Label>
                      <MultiSelect
                        closeMenuOnSelect={false}
                        options={availableServices.map((sr) => ({ value: sr.id, label: sr.title }))}
                        placeholder='Select Services...'
                        isMulti
                        // classNamePrefix="react-select"
                        value={bannerData.services}
                        onChange={value => setBannerData({ ...bannerData, services: value })}
                      />
                    </Form.Group>
                  </Col>


                  <Col md="12">
                    <Form.Group>
                      <Form.Label>Projects:</Form.Label>
                      <MultiSelect
                        closeMenuOnSelect={false}
                        options={availableProjects.map((sr) => ({ value: sr.id, label: sr.title }))}
                        placeholder='Select Projects...'
                        isMulti
                        // classNamePrefix="react-select"
                        value={bannerData.projects}
                        onChange={value => setBannerData({ ...bannerData, projects: value })}
                      />
                    </Form.Group>
                  </Col>


                </Row>
              </Card.Body>
            </Card>

          </Col>
          <Col lg={4}>
            <div className="sticky-sidebar">

              <Card className="mb-3">
                <Card.Header as="h6" className="bg-light">
                  Profile & Status
                </Card.Header>
                <Card.Body>
                  <Row className="gx-2 gy-3">



                    <Col md="12" className='d-flex flex-column align-items-center'>


                      <input id="img-client-profile" className='d-none' type='file' onChange={e => {
                        const file = handleImageUpload(e);
                        if (file) {
                          setBannerData({ ...bannerData, profile: file.file, profileURL: file.url, profilePath: file.path })
                        }

                      }} />
                      <label htmlFor="img-client-profile" className='border rounded-circle py-1 d-flex flex-column justify-content-center position-relative' style={{ height: 180, width: 180 }} >
                        <div className='position-absolute' style={{ right: -50, bottom: 0 }}>
                          <Button variant="link" className="text-secondary fw-medium p-0 me-3" type="button" onClick={e => {
                            setBannerData({ ...bannerData, profile: null, profileURL: null, profilePath: null })
                          }}>
                            {/* <FiDelete className='text-danger' /> */}
                            <span className='small text-danger text-decoration-underline'>remove</span>
                          </Button>
                        </div>
                        {bannerData.profileURL ?
                          <div className="d-flex flex-column align-items-center">

                            <img
                              className="rounded me-2"
                              width={80}
                              style={{ maxHeight: 140 }}
                              src={bannerData.profileURL}
                            />
                            {/* <br />
                            {bannerData.profilePath} */}
                          </div> :
                          <Flex justifyContent='center'>
                            <img src={cloudUpload} alt="" width={50} className="me-2" />
                          </Flex>
                        }
                      </label>
                      <Form.Label className='mt-3'>Profile Image</Form.Label>
                    </Col>



                    <Col md="12">
                      <Form.Group>
                        <Form.Label>Status:</Form.Label>
                        <Form.Select value={bannerData.status} onChange={e => setBannerData({ ...bannerData, status: e.target.value === "true" })}>
                          <option value="true">Active</option>
                          <option value="false">In-Active</option>
                        </Form.Select>
                      </Form.Group>
                    </Col>

                  </Row>
                </Card.Body>
              </Card>



            </div>
          </Col>
          <Col>
            <Card>
              <Card.Body>
                <Row className="flex-between-center">
                  <Col md>
                    <h5 className="mb-2 mb-md-0">You're almost done</h5>
                  </Col>
                  <Col xs="auto">
                    <Link to={'/clients'}>
                      <Button
                        variant="link"
                        className="text-secondary fw-medium p-0 me-3"
                        type="button"
                      >
                        Discard
                      </Button>
                    </Link>
                    <Button variant="primary" type="submit">
                      Add client
                    </Button>
                  </Col>
                </Row>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Form>
    </FormProvider>
  );
};

export default AddClients;
