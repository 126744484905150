import React, { useEffect, useRef, useState } from 'react';
import { Card, Col, Form, FormControl, InputGroup, Row, Button, Accordion, Modal, Spinner } from 'react-bootstrap';
import { FormProvider } from 'react-hook-form';


import cloudUpload from 'assets/img/icons/cloud-upload.svg';




import Flex from 'components/common/Flex';

import { useSelector } from 'react-redux';
import { GetCounter,  UpdatePostCounter, } from 'apis/endpoints';

import { toast } from 'react-toastify';
import { useLocation, useNavigate } from 'react-router-dom';
import { Link } from 'react-router-dom';
import { base_url } from 'apis/constants';



const UpdateCounter = () => {

    const navigate = useNavigate();

    const location = useLocation();
    const sID = location.state?.service_id || 0;
    const agencyID = useSelector(state => state.MEIUser.agencyID);


    const [selectedIcon, setSelectedIcon] = useState(null);
    const handleIconSelect = (icon) => {
        setSelectedIcon(icon);
        // Optionally, send the selected icon to your backend
        // axios.post('/api/save-icon', { icon });
    };

    const [loadingModal, setLoadingModal] = useState(false)


    
    const [bannerData, setBannerData] = useState({
        title: '',
        value: '',
        isCounter: true,

        status: true,

        profile: null,
        profilePath: null,
        profileURL: null,
    });





    const loadServiceData = () => {

        GetCounter(sID).then(res => {
            setBannerData({
                title: res.data.name,
                value: res.data.value,
                isCounter: res.data.as_counter,
                status: res.data.status,

                profile: null,
                profilePath: null,
                profileURL: res.data.image ? `${base_url}${res.data.image}` : null,
            });


        }).catch(err => {
            console.log(err)
        });

    };

    useEffect(() => {
        loadServiceData();
    }, [sID]);




    const handleSubmitBanner = (e) => {
        e.preventDefault();

        setLoadingModal(true)


        
        const finalData = {
            name: bannerData.title,
            value: bannerData.value,
            as_counter: bannerData.isCounter,
            
            status: bannerData.status,

            agency: agencyID
        }

        if (!bannerData.profile && !bannerData.profileURL) {
            finalData.image = null
        }


        UpdatePostCounter(sID, finalData).then(res => {

            let updateCounterPromise = Promise.resolve();

            if (bannerData.profile) {
                const finalPData = new FormData();
                finalPData.append('image', bannerData.profile)
                finalPData.append('agency', agencyID)
                updateCounterPromise = UpdatePostCounter(res.data.id, finalPData);
            }

            Promise.all([updateCounterPromise]).then(() => {
                setLoadingModal(false);
                toast.success(`Counter updated successfully!`, {
                    theme: 'colored'
                });
                navigate('/counters')
            })
        }).catch(err => {
            const errMsg = Object.values(err?.response?.data)[0][0].includes('unique') ? 'Category with same name already exists!' : Object.values(err?.response?.data)[0][0];
            setLoadingModal(false);
            toast.error(errMsg || 'Update counter failed!', {
                theme: 'colored'
            });
        });



    };


    const handleVideoUpload = (event) => {
        const file = event.target.files[0];
        const videoType = /^video\/.*/; // Regex to match any video type

        if (!file?.type?.match(videoType)) {
            toast.error('Only video files are allowed', {
                theme: 'colored'
            });
            return null;
        }

        return { file: file, url: URL.createObjectURL(file), path: file.name }
    };

    const handleFileUpload = (event) => {
        const file = event.target.files[0];

        return { file: file, url: URL.createObjectURL(file), path: file.name }

    };

    const handleImageUpload = (event) => {
        const file = event.target.files[0];
        const imageType = /^(?!.*\.webp$)image.*/;

        if (!file?.type?.match(imageType)) {
            toast.error('Only image files are allowed', {
                theme: 'colored'
            });
            return null;
        }
        if (file?.type?.match(imageType)) {
            return { file: file, url: URL.createObjectURL(file), path: file.name }
        }
    };


    return (
        <FormProvider>

            <Modal show={loadingModal} backdrop="static" keyboard={false} centered>

                <Modal.Body>

                    <div className='d-flex justify-content-center'>
                        <Spinner animation="border" variant="info" size='lg' />
                    </div>

                </Modal.Body>

            </Modal>

            <Form onSubmit={handleSubmitBanner}>
                <Row className="g-3">
                    <Col xs={12}>
                        <Card>
                            <Card.Body>
                                <Row className="flex-between-center">
                                    <Col md>
                                        <h5 className="mb-2 mb-md-0">Edit Counter</h5>
                                    </Col>
                                    <Col xs="auto">
                                        <Link to={'/counters'}>
                                            <Button
                                                variant="link"
                                                className="text-secondary fw-medium p-0 me-3"
                                                type="button"
                                            >
                                                Discard
                                            </Button>
                                        </Link>
                                        <Button variant="primary" type="submit">
                                            Update counter
                                        </Button>
                                    </Col>
                                </Row>
                            </Card.Body>
                        </Card>
                    </Col>
                    <Col lg={8}>

                        <Card className="mb-3">
                            <Card.Header as="h6" className="bg-light">
                                Basic information
                            </Card.Header>
                            <Card.Body>
                                <Row className="gx-2 gy-3">
                                    <Col md="12">
                                        <Form.Group>
                                            <Form.Label>Counter Lablel:</Form.Label>
                                            <InputGroup className="mb-1">
                                                <FormControl value={bannerData.title} onChange={e => setBannerData({ ...bannerData, title: e.target.value })} required />
                                            </InputGroup>

                                            <Form.Control.Feedback type="invalid">
                                                {/* {errors.counterName?.message} */}
                                            </Form.Control.Feedback>
                                        </Form.Group>
                                    </Col>


                                    <Col md="12">
                                        <Form.Group>
                                            <Form.Label>Counter Value:</Form.Label>
                                            <InputGroup className="mb-1">
                                                <FormControl value={bannerData.value} onChange={e => setBannerData({ ...bannerData, value: e.target.value })} required />
                                            </InputGroup>

                                            <Form.Control.Feedback type="invalid">
                                                {/* {errors.counterName?.message} */}
                                            </Form.Control.Feedback>
                                        </Form.Group>
                                    </Col>

                                    <Col md="12">
                                        <Form.Group>
                                            <Form.Label>View As:</Form.Label>
                                            <Form.Select value={bannerData.isCounter} onChange={e => setBannerData({ ...bannerData, isCounter: e.target.value === "true" })}>
                                                <option value="true">Counter</option>
                                                <option value="false">Plain Text</option>
                                            </Form.Select>
                                        </Form.Group>
                                    </Col>

                                </Row>
                            </Card.Body>
                        </Card>





                    </Col>
                    <Col lg={4}>
                        <div className="sticky-sidebar">
                            {/* <BannerPositions /> */}


                            <Card className="mb-3">
                                <Card.Header as="h6" className="bg-light">
                                    Icon & Status
                                </Card.Header>
                                <Card.Body>
                                    <Row className="gx-2 gy-3">



                                        <Col md="12" className='d-flex flex-column align-items-center'>


                                            <input id="img-client-profile" className='d-none' type='file' onChange={e => {
                                                const file = handleImageUpload(e);
                                                if (file) {
                                                    setBannerData({ ...bannerData, profile: file.file, profileURL: file.url, profilePath: file.path })
                                                }

                                            }} />
                                            <label htmlFor="img-client-profile" className='border  py-1 d-flex flex-column justify-content-center position-relative' style={{ height: 180, width: 180 }} >
                                                <div className='position-absolute' style={{ right: -10, bottom: -25 }}>
                                                    <Button variant="link" className="text-secondary fw-medium p-0 me-3" type="button" onClick={e => {
                                                        setBannerData({ ...bannerData, profile: null, profileURL: null, profilePath: null })
                                                    }}>
                                                        {/* <FiDelete className='text-danger' /> */}
                                                        <span className='small text-danger text-decoration-underline'>remove</span>
                                                    </Button>
                                                </div>
                                                {bannerData.profileURL ?
                                                    <div className="d-flex flex-column align-items-center">

                                                        <img
                                                            className="rounded me-2"
                                                            width={80}
                                                            style={{ maxHeight: 140 }}
                                                            src={bannerData.profileURL}
                                                        />
                                                    </div> :
                                                    <Flex justifyContent='center'>
                                                        <img src={cloudUpload} alt="" width={50} className="me-2" />
                                                    </Flex>
                                                }
                                            </label>
                                            <Form.Label className='mt-3'>Counter Icon</Form.Label>
                                        </Col>

                                        <Col md="12">
                                            <Form.Group>
                                                <Form.Label>Status:</Form.Label>
                                                <Form.Select value={bannerData.status} onChange={e => setBannerData({ ...bannerData, status: e.target.value === "true" })}>
                                                    <option value="true">Active</option>
                                                    <option value="false">In-Active</option>
                                                </Form.Select>
                                            </Form.Group>
                                        </Col>
                                    </Row>
                                </Card.Body>
                            </Card>

                        </div>
                    </Col>
                    <Col>

                        <Card>
                            <Card.Body>
                                <Row className="flex-between-center">
                                    <Col md>
                                        <h5 className="mb-2 mb-md-0">You're almost done</h5>
                                    </Col>
                                    <Col xs="auto">
                                        <Link to={'/counters'}>
                                            <Button
                                                variant="link"
                                                className="text-secondary fw-medium p-0 me-3"
                                                type="button"
                                            >
                                                Discard
                                            </Button>
                                        </Link>
                                        <Button variant="primary" type="submit">
                                            Update counter
                                        </Button>
                                    </Col>
                                </Row>
                            </Card.Body>
                        </Card>

                    </Col>
                </Row>
            </Form>
        </FormProvider>
    );
};

export default UpdateCounter;
